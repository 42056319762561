import React from 'react'

const SVGImageBase: React.FC = () => {
  return (
    <svg width='45' height='41' viewBox='0 0 45 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M42.1129 38.1939C42.8943 37.4125 43.3333 36.3527 43.3333 35.2476V12.331C43.3333 11.2259 42.8943 10.1661 42.1129 9.38469C41.3315 8.60329 40.2717 8.16431 39.1666 8.16431H20.4166L16.25 1.91431H5.83329C4.72822 1.91431 3.66842 2.35329 2.88701 3.13469C2.10561 3.9161 1.66663 4.9759 1.66663 6.08097V35.2476C1.66663 36.3527 2.10561 37.4125 2.88701 38.1939C3.66842 38.9753 4.72822 39.4143 5.83329 39.4143H39.1666C40.2717 39.4143 41.3315 38.9753 42.1129 38.1939Z'
        stroke='#3862FA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGImageBase
