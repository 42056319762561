/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActivationState, Client, StompSubscription } from '@stomp/stompjs'
import { IFrame } from '@stomp/stompjs/esm6/i-frame'

import { wsBaseUrl, wsChannelsBaseUrl } from '@app/urls'
import {
  IMessageData,
  IMetaData,
  IResponseMetaData,
  addMsgToBuffer,
  resetSubscription,
  setIsConnected,
  setSubscription,
} from './wsSlice'
import { ISector } from '@app/api-types'
import { imageParams, imageSrc } from './utils'
import { fetchWsToken } from '@app/api'

export const stompMiddleware = ({ dispatch }) => {
  const { REACT_APP_BACKEND_HOST, REACT_APP_BACKEND_PORT, SECURE } = process.env
  const brokerURL =
    process.env.NODE_ENV === 'production'
      ? `wss://${window.location.host}${wsBaseUrl}`
      : `wss://${REACT_APP_BACKEND_HOST}:${REACT_APP_BACKEND_PORT}${wsBaseUrl}`
  console.log('Broker url: ' + brokerURL)
  let client: Client | null

  interface ISubscriptions {
    [key: string]: StompSubscription
  }

  const subscriptions: ISubscriptions = {}

  const handleBeforeConnect = async () => {
    const { token } = await fetchWsToken()
    client.connectHeaders.token = token
    // some kind of error handling may be applied later
    // e.g redirecting to login screen in case of 403 error
  }

  const handleOnConnect = (frame: IFrame) => {
    // restore subscriptions
    for (const key in subscriptions) {
      if (Object.prototype.hasOwnProperty.call(subscriptions, key)) {
        subscriptions[key] = client.subscribe(key, handleSubscribe)
      }
    }
    dispatch(setIsConnected(1)) // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
  }

  const handleOnDisconnect = (frame: IFrame) => {
    dispatch(setIsConnected(0)) // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
  }

  const handleStompError = (frame: IFrame) => {
    console.error('Broker reported error: ' + frame.headers.message)
    console.error('Additional details: ' + frame.body)
  }

  const handleWebSocketError = (error) => {
    console.error('Error with websocket', error)
  }

  const handleChangeState = (state: ActivationState) => {
    // console.log('ActivationState: ', state)
  }

  const handleSubscribe = (message) => {
    if (!message) {
      console.log(`MetaData: получено пустое сообщение`)
      return
    }
    let messageData: IMessageData
    const params = imageParams(message.headers.destination)
    if (params.type === 'data') {
      const { created_at, id, lot, status, weight, detection } = JSON.parse(
        new TextDecoder('utf-8').decode(message.binaryBody),
      ) as IResponseMetaData
      params.id = detection.stored_image_id
      messageData = {
        params,
        metaData: { created_at, id, lot, status, weight } as IMetaData,
      }
    } else {
      messageData = {
        params,
        src: imageSrc(message.binaryBody),
      }
    }
    dispatch(addMsgToBuffer(messageData))
  }

  return (next) => (action) => {
    switch (action.type) {
      case 'connect':
        if (!client) {
          client = new Client({
            brokerURL,
            // debug: (str) => console.log(str),
            reconnectDelay: 1000,
            heartbeatIncoming: 0,
            heartbeatOutgoing: 30000,
          })

          client.beforeConnect = handleBeforeConnect
          client.onConnect = handleOnConnect
          client.onDisconnect = handleOnDisconnect
          client.onStompError = handleStompError
          client.onWebSocketError = handleWebSocketError
          client.onChangeState = handleChangeState

          client.activate()
          dispatch(setIsConnected(2)) // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
        }
        break
      case 'disconnect':
        if (client && client.connected) {
          client.deactivate()
          client = null
          dispatch(setIsConnected(3)) // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
        }
        break
      case 'subscribe':
        if (client && client.connected) {
          const sector: ISector = action.payload
          const channelUrl = `${wsChannelsBaseUrl}/${String(sector.id)}`

          sector.subsectors.forEach((subsector) => {
            const dataDestination = `${channelUrl}/${subsector}/status/*/data`
            const defaultImageDestination = `${channelUrl}/${subsector}/status/*/images/*/default`
            const iconImageDestination = `${channelUrl}/${subsector}/status/*/images/*/icon`
            if (!subscriptions[dataDestination]) {
              subscriptions[dataDestination] = client.subscribe(dataDestination, handleSubscribe)
            }
            if (!subscriptions[defaultImageDestination]) {
              subscriptions[defaultImageDestination] = client.subscribe(defaultImageDestination, handleSubscribe)
            }
            if (!subscriptions[iconImageDestination]) {
              subscriptions[iconImageDestination] = client.subscribe(iconImageDestination, handleSubscribe)
            }
          })
          dispatch(setSubscription(sector))
        }
        break
      case 'unsubscribe':
        if (client && client.connected) {
          for (const key in subscriptions) {
            if (Object.prototype.hasOwnProperty.call(subscriptions, key) && subscriptions[key] !== null) {
              subscriptions[key].unsubscribe()
              delete subscriptions[key]
            }
          }
          const isPaused: boolean = action.payload
          if (!isPaused) {
            dispatch(resetSubscription())
          }
          dispatch(setSubscription(null))
        }

        break
      default:
        return next(action)
    }
  }
}
