import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
  statisticSettings: {
    dateStart: string
    dateEnd: string
  } | null
}

const initialState: InitialState = {
  statisticSettings: null,
}

export const statisticSlice = createSlice({
  name: 'statistic',
  initialState,
  reducers: {
    setStatisticSettings: (state, { payload }) => {
      state.statisticSettings = payload
    },
  },
})

export const { setStatisticSettings } = statisticSlice.actions
export const statistic = statisticSlice.reducer
