import React from 'react'

const SVGRealtimeConveyor: React.FC = () => {
  return (
    <svg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M46.3333 25.6643H38L31.75 44.4143L19.25 6.91431L13 25.6643H4.66663'
        stroke='#3862FA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGRealtimeConveyor
