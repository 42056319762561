import React from 'react'
import styles from './style.module.scss'
import classNames from 'classnames'

type ButtonProps = {
  name: string
  valid: boolean
  isLoading: boolean
}

const Button: React.FC<ButtonProps> = ({ name, valid, isLoading }) => {
  const buttonClassName = classNames(styles.component, { [styles.load]: isLoading })
  return (
    <button type='submit' className={buttonClassName} disabled={!valid}>
      <div className={styles.icon}>
        {name}
        {isLoading && '...'}
      </div>
    </button>
  )
}

export default Button
