import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { fetchUpdateLanguage, fetchLanguage, fetchTimezone, fetchLogin, fetchMe, fetchLogout } from '@app/api'
import { useHistory } from 'react-router-dom'
import { getOptions } from './optionsSlice'
import { getSectors } from './sectors-slice'
import { connectStomp } from './wsSlice'
import { loginUrl } from '@app/urls'

interface IAuthState {
  isLoading: boolean
  isAuth: boolean
  error: string
  lang: 'RU' | 'EN'
  tz: string
}

export const loginUser = createAsyncThunk('auth/login', async (data: { user: string; pass: string }, { dispatch }) => {
  dispatch(loginPending())
  try {
    await fetchLogin(data)
    dispatch(getMe())
  } catch (error) {
    console.log(error)
    dispatch(sendError(error.message))
    console.error('Error login:', error)
    return error.message
  }
})

export const getMe = createAsyncThunk('auth/getMe', async (_, { dispatch }) => {
  try {
    const response = await fetchMe()
    dispatch(loginSuccess())
    sessionStorage.setItem('userInfo', JSON.stringify(response))
    // dispatch(getInitialDataNew({ tryPath: '/conveyor/1' }))
  } catch (error) {
    return error.message
  }
})

export const logoutUser = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
  dispatch(logout())
  try {
    await fetchLogout()
  } catch (error) {
    dispatch(sendError(error.message))
    console.error('Error logout:', error)
    return error.message
  }
})

export const getInitialDataNew = createAsyncThunk(
  'auth/getInitialDataNew',
  async (
    {
      tryPath,
      catchPath = loginUrl,
    }: {
      tryPath?: string
      catchPath?: string
    },
    { dispatch },
  ) => {
    const history = useHistory()
    const pathName = sessionStorage.getItem('pathName')
    console.log(tryPath)
    console.log(pathName)
    if (!tryPath && !pathName) {
      history.push(loginUrl)
      return
    }
    try {
      const response = await Promise.all([
        dispatch(getLanguage()),
        dispatch(getTimeZone()),
        dispatch(getOptions()),
        dispatch(getSectors()),
      ])
      dispatch(loginSuccess())
      dispatch(connectStomp())
      history.push(tryPath || (pathName ?? '/conveyor/1'))
    } catch (error) {
      console.error(error)
      history.push(catchPath)
    }

    dispatch(logout())
    try {
      await fetchLogout()
    } catch (error) {
      dispatch(sendError(error.message))
      console.error('Error initial:', error)
      return error.message
    }
  },
)

export const setLanguage = createAsyncThunk('auth/setLanguage', async (lang: string) => {
  try {
    await fetchUpdateLanguage(lang)
    return lang
  } catch (error) {
    return error.message
  }
})

export const getLanguage = createAsyncThunk('auth/getLanguage', async () => {
  try {
    const lang = await fetchLanguage()
    return lang.code
  } catch (error) {
    return error.message
  }
})

export const getTimeZone = createAsyncThunk('auth/getTimeZone', async () => {
  try {
    const tz = await fetchTimezone()
    return tz
  } catch (error) {
    return error.message
  }
})
const initialState: IAuthState = {
  isLoading: false,
  isAuth: false,
  error: '',
  lang: 'RU',
  tz: 'Etc/UTC',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginPending: (state) => {
      state.isLoading = true
    },
    loginSuccess: (state) => {
      state.isLoading = false
      state.isAuth = true
      state.error = ''
    },
    sendError: (state, { payload }) => {
      state.isLoading = false
      state.isAuth = false
      state.error = payload
    },
    changeLang: (state, { payload }) => {
      state.lang = payload.lang
    },
    logout: (state) => {
      state.isLoading = false
      state.isAuth = false
      state.error = ''
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setLanguage.fulfilled, (state, action) => {
      state.lang = action.payload === 'en-us' ? 'EN' : 'RU'
    })
    builder.addCase(setLanguage.rejected, (state, action) => {
      state.error = action.payload as string
    })
    builder.addCase(getLanguage.fulfilled, (state, action) => {
      state.lang = action.payload === 'en-us' ? 'EN' : 'RU'
    })
    builder.addCase(getLanguage.rejected, (state, action) => {
      state.error = action.payload as string
    })
    builder.addCase(getTimeZone.fulfilled, (state, action) => {
      state.tz = action.payload
    })
    builder.addCase(getTimeZone.rejected, (state, action) => {
      state.error = action.payload as string
    })
  },
})

export const { loginPending, loginSuccess, sendError, changeLang, logout } = authSlice.actions

export default authSlice.reducer
