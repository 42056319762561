import React from 'react'
import LoginForm from './components/loginForm'
import styles from './style.module.scss'

export const LoginPage: React.FC = () => {
  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <svg xmlns='http://www.w3.org/2000/svg' width='564.91' height='118.98' viewBox='0 0 564.91 118.98'>
          <g>
            <g>
              <circle cx='53.27' cy='55.64' r='53.27' fill='#3ba4dd' />
              <text transform='translate(38.7 63.14)' fontSize='52.62' fill='#fff' fontFamily='Impact'>
                ...
              </text>
            </g>
            <text transform='translate(126.95 86.9)' fontSize='97.06' fill='#1d1d1b' fontFamily='Impact'>
              face
              <tspan x='174.6' y='0' fill='#3ba4dd'>
                metric
              </tspan>
            </text>
          </g>
        </svg>

        <div className={styles.form}>
          <LoginForm />
        </div>
      </div>
    </div>
  )
}
