import { useEffect, useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'

export const useValidation = (value, validations) => {
  const lang: string = useAppSelector((state) => state.auth.lang)
  const [error, setError] = useState('')

  const emptyFieldText = { RU: 'Поле должно быть заполнено', EN: 'The field should be filled' }
  const loginErrorText = { RU: 'Логин введен некорректно', EN: 'Incorrect login' }
  const passwordErrorText = { RU: 'Пароль введен некорректно', EN: 'Incorrect password' }

  useEffect(() => {
    for (const validation in validations) {
      if (validation) {
        let isError = false
        switch (validation) {
          case 'isEmpty': {
            if (value) {
              setError('')
            } else {
              setError(emptyFieldText[lang])
              isError = true
            }
            break
          }
          case 'isLogin': {
            const re = /^[a-zA-Z0-9]{2,20}$/
            const check = re.test(String(value).toLowerCase())
            if (check) {
              setError('')
            } else {
              setError(loginErrorText[lang])
              isError = true
            }
            break
          }
          case 'isPassword': {
            const re = /^[a-zA-Z0-9]{2,20}$/
            const check = re.test(String(value).toLowerCase())
            if (check) {
              setError('')
            } else {
              setError(passwordErrorText[lang])
              isError = true
            }
            break
          }
          default:
            setError('')
        }
        if (isError) {
          break
        }
      }
    }
  }, [value])

  return error
}
