/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */

// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISector } from '@app/api-types'

export interface IMetaData {
  created_at: string
  id: number
  lot: string
  status: number
  weight: string
}

export interface IResponseMetaData extends IMetaData {
  detection: {
    stored_image_id: number
  }
}

export interface IImageData {
  data: IMetaData | Record<string, never>
  default: string
  icon: string
}

type TImageDataWithoutDefault = Omit<IImageData, 'default'>
export interface IImageParams {
  view: string
  type: string
  id?: number
}

export interface IMessageData {
  params: IImageParams
  metaData?: IMetaData
  src?: string
}

interface IImageRecord {
  [image_id: number]: IImageData
}

interface IBufferTypes {
  [key: string]: IImageRecord | Record<string, never>
}

interface IViewTypes {
  [key: string]: IImageData | Record<string, never>
}

interface IWebSocketState {
  connectionState: number // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
  sector: ISector | null
  buffer: IBufferTypes | Record<string, never>
  activeView: IViewTypes | Record<string, never>
  historyStack: TImageDataWithoutDefault[]
}

const initialWebSocketState: IWebSocketState = {
  connectionState: 0, // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
  sector: null,
  buffer: {},
  activeView: {},
  historyStack: [],
}

const wsSlice = createSlice({
  name: 'webSocket',
  initialState: initialWebSocketState,
  reducers: {
    setIsConnected: (state, action: PayloadAction<number>) => {
      state.connectionState = action.payload
    },
    setSubscription: (state, action: PayloadAction<ISector | null>) => {
      state.sector = action.payload
    },
    addMsgToBuffer: (state, action: PayloadAction<IMessageData | null>) => {
      if (action.payload === null) {
        state.buffer = {}
      } else {
        const { id, type, view } = action.payload.params
        const msgData = state.buffer[view] || {}
        const idData = msgData[id] || { data: {}, default: '', icon: '' }
        action.payload.metaData && (idData.data = action.payload.metaData)
        type === 'default' && (idData.default = action.payload.src)
        type === 'icon' && (idData.icon = action.payload.src)
        msgData[id] = idData
        if (Object.keys(msgData[id].data).length > 0 && msgData[id].default && msgData[id].icon) {
          state.activeView[view] = msgData[id]
          const stackTmp = state.historyStack
          stackTmp.length === 20 && stackTmp.pop()
          stackTmp.unshift({ data: msgData[id].data, icon: msgData[id].icon })
          state.historyStack = stackTmp
          delete msgData[id]
        }
        state.buffer[view] = msgData
      }
    },
    resetSubscription: (state) => {
      state.buffer = {}
      state.activeView = {}
      state.sector = null
      state.historyStack = []
    },
  },
  extraReducers: (builder) => {},
})

export const { setIsConnected, setSubscription, addMsgToBuffer, resetSubscription } = wsSlice.actions

export const connectStomp = () => ({ type: 'connect' })
export const disconnectStomp = () => ({ type: 'disconnect' })
export const subscribeStomp = (payload) => ({ type: 'subscribe', payload })
export const unsubscribeStomp = (payload = false) => ({ type: 'unsubscribe', payload })

export default wsSlice.reducer
