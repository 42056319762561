import React from 'react'

const SVGStatistics: React.FC = () => {
  return (
    <svg width='27' height='37' viewBox='0 0 27 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.5 35.3309V14.4976' stroke='#3862FA' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M26 35.3309V1.99756' stroke='#3862FA' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1 35.3309V26.9976' stroke='#3862FA' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default SVGStatistics
