import React, { SyntheticEvent } from 'react'

import { Popper } from '@material-ui/core'

import style from './style.module.scss'

interface IPopperProps {
  check: boolean
  idElement: string
  content: JSX.Element
}

const PopperComponent: React.FC<IPopperProps> = (props) => {
  const { check, idElement, content } = props
  const id = check ? 'simple-popper' : undefined
  return (
    <Popper
      className={style.poper}
      onClick={(ev: SyntheticEvent) => {
        ev.stopPropagation()
      }}
      id={id}
      anchorEl={document.getElementById(idElement)}
      open={check}
    >
      {content}
    </Popper>
  )
}

export default PopperComponent
