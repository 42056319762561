/* eslint-disable camelcase */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchPeriodHistory, fetchSpecs, fetchImageById, fetchProducts } from '@app/api'
import { IHistoryData, ISearch } from '@app/api-types'

export interface Product {
  id: number
  lot: string
  weight: string
  date: string
  created_at: string
  sector_id: number
  status: number
  detection: {
    id: number
    stored_image_id: number
    created_at: string
  }
}

interface ProductType {
  id: number
  type: string
  value: string
}

interface Image {
  image_id: number
  uploaded_at: string
  variants: ImageVariant[]
}

interface ImageVariant {
  variant_id: number
  image_id: number
  kind: string
  generated_at: string
  size_px: {
    x: number
    y: number
  }
}

type TInitialState = {
  sector: any
  historyYears: number[]
  historyMonths: number[]
  historySectors: any
  productTypes: ProductType[]
  products: Product[]
  currentImage: Image
  filterSettings: {
    dateStart: string
    dateEnd: string
    filter: {
      color_errors: boolean
      print_errors: boolean
    }
  } | null
}

export const getImageVariants = createAsyncThunk(
  'archive/image-variants',
  async (id: number) => await fetchImageById(id),
)

export const getProducts = createAsyncThunk('archive/products', async (search: ISearch) => await fetchProducts(search))

export const getProductTypes = createAsyncThunk(
  'archive/product-types',
  async (type: 'lot' | 'weight') => await fetchSpecs(type),
)

export const getHistorySectors = createAsyncThunk(
  'archive/sectors',
  async (historyData: IHistoryData) => await fetchPeriodHistory(historyData),
)

export const getHistoryYears = createAsyncThunk(
  'archive/years',
  async (historyData: IHistoryData) => await fetchPeriodHistory(historyData),
)

export const getHistoryMonths = createAsyncThunk(
  'archive/months',
  async (historyData: IHistoryData) => await fetchPeriodHistory({ year: historyData.year }),
)

const initialState: TInitialState = {
  sector: {},
  historyYears: [],
  historyMonths: [],
  historySectors: [],
  productTypes: [],
  products: [],
  currentImage: null,
  filterSettings: null,
}

export const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    setFilterSettings: (state, { payload }) => {
      state.filterSettings = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistoryYears.fulfilled, (state, { payload }) => {
      state.historyYears = payload.reverse()
    })
    builder.addCase(getHistoryMonths.fulfilled, (state, { payload }) => {
      state.historyMonths = payload.reverse()
    })
    builder.addCase(getHistorySectors.fulfilled, (state, { payload }) => {
      state.historySectors = payload
    })
    builder.addCase(getProductTypes.fulfilled, (state, { payload }) => {
      state.productTypes = payload
    })
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      state.products = payload
    })
    builder.addCase(getImageVariants.fulfilled, (state, { payload }) => {
      state.currentImage = payload
    })
  },
})

export const { setFilterSettings } = archiveSlice.actions

export const archive = archiveSlice.reducer
