import React from 'react'
import { List, ListItem } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useAppSelector } from '@app/redux/hooks'

interface SectorListProps {
  path: string
  list: {
    id: number
    name: string
  }[]
  onClose: () => void
}
export const SectorList = (props: SectorListProps) => {
  const lang: string = useAppSelector((state) => state.auth.lang)
  const sectorText = { RU: 'Сектор', EN: 'Sector' }

  const { list, path, onClose } = props
  return (
    <List>
      {list.map(({ id }) => {
        return (
          <ListItem key={id}>
            <Link to={`${path}/${id}`} onClick={onClose}>
              {sectorText[lang]} {id}
            </Link>
          </ListItem>
        )
      })}
    </List>
  )
}
