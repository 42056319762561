import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { DatePicker, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import 'antd/dist/antd.css'
import moment from 'moment'
import { useAppSelector } from '@app/redux/hooks'
import { OptionTable, IColor, ISpecs } from '@app/api-types'
import { fetchColors, fetchSpecs } from '@app/api'

export interface DataType {
  key: React.Key
  sector: number
  colors: number[]
  lots: number[]
  date: string
}

export interface OptionsTableProps {
  id: number
  sector: number
  lots: number[]
  colors: number[]
  date: string
}

export interface TableData {
  data: OptionTable[]
  setTabledata: (data: DataType[]) => void
}

export const OptionsTable: React.FC<TableData> = ({ data, setTabledata }) => {
  const { lang } = useAppSelector((state) => state.auth)
  const sectors = useAppSelector((state) => state.sectors.sectors)

  const sectorText = { RU: 'Сектор', EN: 'Sector' }
  const colorText = { RU: 'Цвет', EN: 'Color' }
  const lotText = { RU: 'Лот', EN: 'Lot' }
  const dateText = { RU: 'Дата', EN: 'Date' }
  const empityTableText = { RU: 'Параметры не установлены', EN: 'Parameters are not set' }

  const [dataSource, setDataSource] = React.useState<DataType[]>([])
  const [loading, setLoading] = useState(false)
  const [colors, setColors] = React.useState<IColor[]>([])
  const [productTypes, setProductTypes] = React.useState<ISpecs[]>([])
  const getColors = async () => {
    try {
      const response: IColor[] = await fetchColors()
      setColors(response)
    } catch (error) {
      console.error('Error fetching colors:', error)
    }
  }

  const getProductTypes = async () => {
    try {
      const response: ISpecs[] = (await fetchSpecs('lot')) || []
      setProductTypes(response)
    } catch (error) {
      console.error('Error fetching productTypes:', error)
    }
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([getColors(), getProductTypes()]).then(() => setLoading(false))
  }, [])

  React.useEffect(() => {
    if (!loading) {
      const source: DataType[] = []
      for (let i = 0; i < sectors.length; i++) {
        source.push({
          key: data[i]?.id || sectors[i].id,
          sector: data[i]?.sector_id || sectors[i].id,
          colors: data[i]?.colors.map((elem) => colors.find((el) => el.id === elem).id) || [],
          lots: data[i]?.lots.map((elem) => productTypes.find((el) => el.id === elem).id) || [],
          date: data[i]?.date || '',
        })
      }
      setDataSource(source)
    }
  }, [data, loading])

  const colorOptions = colors.map((color) => ({
    value: color.id,
    label: <span style={{ color: `${color.name}` }}>{color.name}</span>,
  }))

  const productTypesOptions = productTypes.map((type) => ({
    value: type.id,
    label: type.value,
  }))

  const handleChange = (key: React.Key, option: string, value: number[] | string) => {
    const newTableData = dataSource.map((item) => {
      return item.key === key ? { ...item, [option]: value } : item
    })
    setTabledata(newTableData)
    setDataSource(newTableData)
  }

  const columns: ColumnsType<DataType> = [
    {
      title: sectorText[lang],
      dataIndex: 'sector',
      width: 163,
      render: (_, record) => (
        <span>
          {sectorText[lang]} {record.sector}
        </span>
      ),
    },
    {
      title: lotText[lang],
      dataIndex: 'lots',
      width: 243,
      render: (_, record) =>
        !data ? (
          <span>LOAD</span>
        ) : (
          <Select
            className={styles.select}
            mode='multiple'
            showArrow
            showSearch={false}
            maxTagCount='responsive'
            bordered
            value={record.lots}
            placeholder={lotText[lang]}
            style={{ width: '100%' }}
            listHeight={160}
            options={productTypesOptions}
            onChange={(value: number[]) => {
              handleChange(record.key, 'lots', value)
            }}
          />
        ),
    },
    {
      title: colorText[lang],
      dataIndex: 'colors',
      width: 163,
      render: (_, record) => (
        <Select
          mode='multiple'
          showArrow
          showSearch={false}
          maxTagCount='responsive'
          className={styles.select}
          bordered
          placeholder={colorText[lang]}
          value={record.colors}
          style={{ width: '100%' }}
          listHeight={160}
          options={colorOptions}
          onChange={(value: number[]) => {
            handleChange(record.key, 'colors', value)
          }}
        />
      ),
    },
    {
      title: dateText[lang],
      dataIndex: 'date',
      width: 137,
      render: (text: string, record: { key: React.Key }) => (
        <DatePicker
          value={text ? moment(text, 'YYYY-MM-DD') : null}
          bordered={false}
          onChange={(_, dateString) => handleChange(record.key, 'date', dateString)}
          placeholder={dateText[lang]}
          size='small'
          popupStyle={{ width: 500 }}
          format='YYYY-MM-DD'
          className={styles.DatePicker}
        />
      ),
    },
  ]

  return (
    <div className={styles.tableWrapper}>
      <Table
        className={styles.table}
        rowClassName={styles.row}
        scroll={dataSource.length > 3 && { y: 240 }}
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        style={{ marginBottom: 16 }}
        locale={{ emptyText: empityTableText[lang] }}
      />
    </div>
  )
}
