import React from 'react'

const SVGCaretMini = () => {
  return (
    <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M6.58323 1.16667L3.99657 3.75334L1.4099 1.16667C1.28535 1.04183 1.11625 0.97168 0.939902 0.97168C0.763557 0.97168 0.594457 1.04183 0.469902 1.16667C0.209902 1.42667 0.209902 1.84667 0.469902 2.10667L3.5299 5.16667C3.7899 5.42667 4.2099 5.42667 4.4699 5.16667L7.5299 2.10667C7.7899 1.84667 7.7899 1.42667 7.5299 1.16667C7.2699 0.913337 6.84323 0.90667 6.58323 1.16667Z'
        fill='#626E99'
      />
    </svg>
  )
}

export default SVGCaretMini
