import React, { useState } from 'react'

import { useAppSelector } from '@app/redux/hooks'

import Select from '@components/Select'

import styles from './style.module.scss'

interface IOptions {
  handleChangeLang: (lang: string) => void
}
type ILangs = 'RU' | 'EN'

export const SelectLanguage: React.FC<IOptions> = (props) => {
  const { handleChangeLang } = props

  const lang: string = useAppSelector((state) => state.auth.lang)

  const langText = { RU: 'Язык системы', EN: 'System language' }

  const [selectedLang, setLang] = useState(lang)

  const [shownLangs, setShownLangs] = useState(false)

  const langs: Array<ILangs> = ['RU', 'EN']

  const changeSortedValue = (lang: ILangs) => {
    setLang(() => lang)

    setShownLangs((prevValue) => !prevValue)
  }

  return (
    <div className={styles.section}>
      <p className={styles.languageHeader}>{langText[lang]}</p>
      <div className={styles.parametersLang}>
        <Select
          isOpen={shownLangs}
          itemArray={langs}
          selectedItem={selectedLang}
          changeSelectedItem={(lang: ILangs) => {
            handleChangeLang(lang)
            changeSortedValue(lang)
          }}
          onClick={() => {
            setShownLangs((prevValue) => !prevValue)
          }}
        />
      </div>
    </div>
  )
}
