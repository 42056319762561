import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from '@app/redux/hooks'

import { RangePickerComponent } from '@components/RangePicker'
import { SwitcherComponent } from '@components/Switcher'

import Styles from '../style.module.scss'
import moment from 'moment'
import { setFilterSettings } from '@app/redux/archive-slice'
import { format } from 'date-fns'

interface IReportContent {
  modalCallback: () => void
}

export const SearchPhotoContent: React.FC<IReportContent> = (props) => {
  const { modalCallback } = props

  const history = useHistory()

  const dispatch = useAppDispatch()

  const lang: 'RU' | 'EN' = useAppSelector((state) => state.auth.lang)
  const titleText = { RU: 'Поиск', EN: 'Search' }
  const subTitleText = { RU: 'Выбор периода', EN: 'Period selection' }
  const applyFilterText = { RU: 'Применить фильтр', EN: 'Apply filter' }
  const showText = { RU: 'Показать только', EN: 'Show only' }

  const [checkedItems, setCheckedItems] = useState({
    color_errors: true,
    print_errors: true,
  })

  const checkboxItems = useMemo(
    () => [
      {
        text: {
          RU: 'Ошибки цвета',
          EN: 'Color errors',
        },
        status: checkedItems.color_errors,
        callback: () => {
          setCheckedItems((prevValue) => ({
            ...prevValue,
            color_errors: !prevValue.color_errors,
          }))
        },
      },
      {
        text: {
          RU: 'Ошибки печати',
          EN: 'Printing errors',
        },
        status: checkedItems.print_errors,
        callback: () => {
          setCheckedItems((prevValue) => {
            return {
              ...prevValue,
              print_errors: !prevValue.print_errors,
            }
          })
        },
      },
    ],
    [checkedItems.color_errors, checkedItems.print_errors],
  )

  return (
    <>
      <h2 className={Styles.title}>{titleText[lang]}</h2>
      <h4 className={Styles.subtitle}>{subTitleText[lang]}</h4>
      <RangePickerComponent
        textButton={applyFilterText[lang]}
        handleChange={(dateInterval) => {
          const date0 = moment()
            // .utc()
            .set({
              year: dateInterval[0].year(),
              month: dateInterval[0].month(),
              date: dateInterval[0].date(),
              hour: dateInterval[0].hour(),
              minute: dateInterval[0].minute(),
              second: dateInterval[0].second(),
            })
            .toDate()
          const date1 = moment()
            // .utc()
            .set({
              year: dateInterval[1].year(),
              month: dateInterval[1].month(),
              date: dateInterval[1].date(),
              hour: dateInterval[1].hour(),
              minute: dateInterval[1].minute(),
              second: dateInterval[1].second(),
            })
            .toDate()
          const information = {
            dateStart: format(date0, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`),
            dateEnd: format(date1, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`),
            filter: checkedItems,
          }
          dispatch(setFilterSettings(information))
          // history.push(`/image-base/filter?information=${JSON.stringify(information)}`)
          history.push(`/image-base/filter`)
          modalCallback()
        }}
        handleOnlyClick
      >
        <section className={Styles.items_container}>
          <h4 className={Styles.subtitle}>{showText[lang]}</h4>
          <ul className={Styles.list}>
            {checkboxItems.map((item, index) => (
              <li key={index} className={Styles.item}>
                <SwitcherComponent width={38} height={20} status={item.status} callback={item.callback} />
                <span>{item.text[lang]}</span>
              </li>
            ))}
          </ul>
        </section>
      </RangePickerComponent>
    </>
  )
}
