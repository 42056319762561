import React from 'react'
const SVGHeaderBackArrow = () => {
  return (
    <svg
      style={{ cursor: 'pointer' }}
      width='46'
      height='25'
      viewBox='0 0 46 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M9 15L4 10L9 5' stroke='#626E99' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M20 21V14C20 12.9391 19.5786 11.9217 18.8284 11.1716C18.0783 10.4214 17.0609 10 16 10H4'
        stroke='#626E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGHeaderBackArrow
