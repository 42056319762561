/* eslint-disable camelcase */
import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react'
import styles from '../style.module.scss'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Pagination from '@mui/material/Pagination'
import { useAppSelector, useAppDispatch } from '@app/redux/hooks'
import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { SwitcherComponent } from '@components/Switcher'
import { getImageVariants, getProducts, Product } from '@app/redux/archive-slice'
import { getOptions } from '@app/redux/optionsSlice'
import { calculateShiftTime } from '../utils/calculate-shift-time'
import { format, getTime, parseISO } from 'date-fns'
import { ProductList } from '../components/product-list'
import { BASE_URL, imagesUrl } from '@app/urls'
import { saveAs } from 'file-saver'
import { ImageModal } from '../components/image-modal'
import { fetchProductsPaging } from '@app/api'
import { PagingSearch } from '@app/api-types'
import { getInitialData } from '@app/redux/getInitialData'
interface Params {
  year: string
  month: string
  day: string
  shift: string
  sector: string
}

export const SectorPage = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const params = useParams<Params>()
  const dispatch = useAppDispatch()
  const [lang, isAuth] = useAppSelector((state) => [state.auth.lang, state.auth.isAuth])
  const sectors = useAppSelector((state) => state.sectors.sectors)
  const shifts = useAppSelector((state) => state.options.dataOptions?.shifts)
  const { currentImage } = useAppSelector((state) => state.archive)
  const { year, month, day, shift, sector } = params
  const [status, setStatus] = React.useState<number>(null)
  const [products, setProducts] = React.useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [openImageModal, setOpenImageModal] = React.useState(false)
  const [noDefectsImageLoading, setNoDefectsImageLoading] = React.useState(false)
  const [currentProduct, setCurrentProduct] = useState<Product>()
  const [count, setCount] = React.useState<number>(0)
  const [newNumberPage, setNewNumberPage] = React.useState<number>(1)
  const [currentNumberPage, setCurrentNumberPage] = React.useState<number>(1)
  const [from, setFrom] = React.useState<string>('')
  const [till, setTill] = React.useState<string>('')

  const unSuccesstext = { RU: 'Только неуспешные', EN: 'Only the unsuccessful' }
  const loadingDataText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading ...' }
  const loadNoperiodDataText = { RU: 'За данный период нет данных', EN: 'No data available for this period' }

  const getPaging = async (data: PagingSearch) => {
    try {
      const response: { count: number } = await fetchProductsPaging(data)
      const totalPage = Math.ceil(response.count / 20)
      setCount(totalPage)
    } catch (error) {
      console.error('Error fetching paging:', error)
    }
  }

  const closeImageModal = () => {
    setOpenImageModal(false)
  }
  useEffect(() => {
    if (!shifts) {
      dispatch(getOptions())
    }
  }, [shifts])

  useEffect(() => {
    if (isAuth) {
      const { startHours, startMinutes, startsSeconds, longTimeMilliseconds } = calculateShiftTime(+shift, shifts)
      const shiftStart = new Date(+year, +month - 1, +day, startHours, startMinutes, startsSeconds)
      setFrom(format(shiftStart, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`))
      const milliseconds = getTime(shiftStart)
      setTill(format(milliseconds + longTimeMilliseconds, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`))
    }
  }, [shifts, isAuth])

  useEffect(() => {
    if (from && till && isAuth) {
      setIsLoading(true)
      getPaging({
        from,
        till,
        shift_id: +shift,
        sector_id: +sector,
        status,
      }).then(() =>
        dispatch(
          getProducts({
            asc: true,
            status,
            shift_id: +shift,
            from,
            till,
            limit: 20,
            start_id: 0,
            sector_id: +sector,
            offset: 0,
          }),
        ).then((res) => {
          const productsArray: Product[] = [...res.payload]
          setProducts(productsArray)
          setCurrentNumberPage(newNumberPage)
        }),
      )
      setIsLoading(false)
    }
  }, [shift, shifts, from, till, isAuth, status])

  useEffect(() => {
    const pageCount = newNumberPage - currentNumberPage
    const getStartId = (currentNumberPage: number, newNumberPage: number, products: Product[]) => {
      if (currentNumberPage === 1 && newNumberPage === 1) {
        return 0
      }
      if (newNumberPage > currentNumberPage) {
        return products[products.length - 1].id
      } else {
        return products[0].id
      }
    }
    if (from && till && isAuth) {
      setIsLoading(true)
      dispatch(
        getProducts({
          asc: pageCount >= 0,
          status,
          shift_id: +shift,
          from,
          till,
          limit: 20,
          start_id: getStartId(currentNumberPage, newNumberPage, products),
          sector_id: +sector,
          offset: pageCount === 0 ? 0 : 20 * (Math.abs(pageCount) - 1),
        }),
      ).then((res) => {
        const productsArray: Product[] = [...res.payload]
        pageCount < 0 ? setProducts(productsArray.reverse()) : setProducts(productsArray)
        setCurrentNumberPage(newNumberPage)
        setIsLoading(false)
      })
    }
  }, [newNumberPage])

  const handleImageClick = useCallback(
    (id: number, imageId: number) => {
      const currentProduct = products.find((product) => product.id === id)
      dispatch(getImageVariants(imageId))
      setCurrentProduct(currentProduct)
      setOpenImageModal(true)
    },
    [products],
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const toggleShow = () => {
    setStatus(() => (status === null ? -1 : null))
    setNewNumberPage(1)
    setCurrentNumberPage(1)
  }

  const goToPage = (numberPage: number) => {
    setNewNumberPage(() => numberPage)
  }

  const createNewCanvas = (
    _width: number,
    _height: number,
  ): { newCanvasContext: CanvasRenderingContext2D; newCanvas: HTMLCanvasElement } => {
    const newCanvas: HTMLCanvasElement = document.createElement('canvas')
    const newCanvasContext: CanvasRenderingContext2D = newCanvas.getContext('2d')
    newCanvas.width = _width
    newCanvas.height = _height
    return { newCanvasContext, newCanvas }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const createOriginalImage = (id: number) => {
    const originalImageVariant = currentImage.variants.find((variant) => variant.kind === 'ORIG')
    const boxImage = document.createElement('img')
    boxImage.src = `${BASE_URL}${imagesUrl}/${currentImage.image_id}/variants/${originalImageVariant.variant_id}/data`
    return boxImage
  }
  const downloadImage = (id: number) => {
    if (!currentImage) {
      return
    }
    setNoDefectsImageLoading(true)
    const boxImage = createOriginalImage(id)
    boxImage.onload = () => {
      const imageWidth = boxImage.naturalWidth
      const imageHeight = boxImage.naturalHeight
      const { newCanvasContext, newCanvas } = createNewCanvas(imageWidth, imageHeight)
      newCanvasContext.drawImage(boxImage, 0, 0, imageWidth, imageHeight)
      saveAs(
        newCanvas.toDataURL('image/png'),
        `${currentImage.image_id}-${format(parseISO(currentImage.uploaded_at), 'HH:mm:ss')}-${format(
          parseISO(currentImage.uploaded_at),
          'dd.MM.yyyy',
        )}.png`,
      )
      setNoDefectsImageLoading(false)
    }
  }

  useLayoutEffect(() => {
    sectors.length > 0 ? sessionStorage.setItem('pathName', pathname) : getInitialData({ dispatch, history })
  }, [sectors])

  return (
    <div className={styles.component}>
      <Header />
      <div className={styles.switchBack__container}>
        <SwitcherComponent callback={toggleShow} width={38} height={20} status={status !== null} />
        <p>{unSuccesstext[lang]}</p>
      </div>
      <NavigateMenu />
      <div className={`${styles.main_container} ${styles.image_container}`}>
        {!isLoading && products.length !== 0 ? (
          <>
            {count > 1 && (
              <Pagination
                onChange={(_, page) => {
                  goToPage(page)
                }}
                className={styles.pagination_top}
                page={newNumberPage}
                count={count}
                color='primary'
                size='large'
                boundaryCount={2}
              />
            )}
            <ProductList products={products} handleImageClick={handleImageClick} />
            {count > 1 && (
              <Pagination
                onChange={(_, page) => {
                  goToPage(page)
                }}
                className={styles.pagination_bottom}
                page={newNumberPage}
                count={count}
                color='primary'
                size='large'
                boundaryCount={2}
              />
            )}
          </>
        ) : (
          <div className={styles.load_text}>
            {isLoading ? `${loadingDataText[lang]}` : `${loadNoperiodDataText[lang]}`}
          </div>
        )}
      </div>
      <ImageModal
        openImageModal={openImageModal}
        closeImageModal={closeImageModal}
        noDefectsImageLoading={noDefectsImageLoading}
        lang={lang}
        downloadImage={downloadImage}
        currentProduct={currentProduct}
      />
    </div>
  )
}
