import { IImageParams } from './wsSlice'

export const imageSrc = (imageBytes: BlobPart) => {
  const blob = new Blob([imageBytes], { type: 'image/jpeg' }) // Adjust the MIME type as needed
  return URL.createObjectURL(blob)
}

export const imageParams = (destination: string): IImageParams => {
  const distArray = destination.split('/')

  const len = distArray.length
  let viewValue = ''
  let typeValue = ''
  let idValue = 0

  if (len > 0) {
    viewValue = distArray[3]
    typeValue = distArray[len - 1]
    idValue = Number(distArray[len - 2]) || 0
  }

  return {
    view: viewValue,
    type: typeValue,
    id: idValue,
  }
}
