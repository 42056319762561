import React from 'react'
import { useAppSelector } from '@app/redux/hooks'

import Styles from './style.module.scss'

export const PageNotFount = () => {
  const lang: string = useAppSelector((state) => state.auth.lang)
  const titleText = { RU: 'Страница не найдена', EN: 'Page not found' }
  return (
    <div className={Styles.found_container}>
      <h1>{titleText[lang]}</h1>
    </div>
  )
}
