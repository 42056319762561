import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { Link, useParams } from 'react-router-dom'
import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { getHistorySectors } from '@app/redux/archive-slice'
import SVGFolder from '@icons/folder'
import styles from '../style.module.scss'

interface Params {
  year: string
  month: string
  day: string
  shift: string
}
export const ShiftPage = () => {
  const params = useParams<Params>()
  const { year, month, day, shift } = params
  const dispatch = useAppDispatch()
  const lang = useAppSelector((state) => state.auth.lang)
  const { historySectors } = useAppSelector((state) => state.archive)
  const defaultLoadingText = { RU: 'Данные отсутствуют', EN: 'No data available' }
  const sectorText = { RU: 'Сектор', EN: 'Sector' }

  useEffect(() => {
    dispatch(
      getHistorySectors({
        year: +year,
        month: +month,
        day: +day,
        shift: +shift,
      }),
    )
  }, [])

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {historySectors?.length > 0 &&
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          historySectors.map(({ id, name }, index) => {
            return (
              <Link
                key={id + index}
                to={`/image-base/${year}/${month}/${day}/${shift}/${id}`}
                className={styles.folder}
              >
                <SVGFolder />
                <p>
                  {sectorText[lang]} {id}
                </p>
              </Link>
            )
          })}
        {historySectors.length === 0 && <p className={styles.load_text}>{defaultLoadingText[lang]}</p>}
      </div>
    </div>
  )
}
