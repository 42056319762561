import React from 'react'
import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'

import styles from './style.module.scss'

export const LogoutPage: React.FC = () => {
  return (
    <div className={styles.main_container}>
      <div className={styles.component}>
        <Header />
        <NavigateMenu />
      </div>
    </div>
  )
}
