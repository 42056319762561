import React, { FC, useCallback, useState } from 'react'

import type { Moment } from 'moment-timezone'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import { DatePicker } from 'antd'
import 'antd/dist/antd.css'
import localeRu from 'antd/es/date-picker/locale/ru_RU'
import localeEn from 'antd/es/date-picker/locale/en_US'

import { useAppSelector } from '@app/redux/hooks'
import { IntervalDateComponent } from './components/IntervalDate'
import Styles from './style.module.scss'

interface IRangePickerProps {
  handleChange: (dateString: Moment[]) => void
  handleOnlyClick?: boolean
  classPicker?: string
  textButton?: string
  children?: React.ReactNode
}

export const FORMAT = 'HH:mm DD.MM.YYYY'

export const RangePickerComponent: FC<IRangePickerProps> = (props) => {
  const lang = useAppSelector((state) => state.auth.lang)
  const tz: string = useAppSelector((state) => state.auth.tz)

  const buttonText = { RU: 'Выбрать', EN: 'Select' }

  const { handleChange, handleOnlyClick = true, classPicker, textButton = buttonText[lang], children } = props

  const { RangePicker } = DatePicker

  const [value, setValue] = useState<[Moment, Moment] | null>(null)

  const chooseToday = useCallback(() => setValue(() => [moment().tz(tz).startOf('day'), moment().tz(tz)]), [])

  const chooseWeek = useCallback(() => setValue(() => [moment().tz(tz).startOf('week'), moment().tz(tz)]), [])

  const chooseMonth = useCallback(() => setValue(() => [moment().tz(tz).startOf('month'), moment().tz(tz)]), [])
  const resetValue = useCallback(() => setValue(() => null), [])

  return (
    <>
      <RangePicker
        locale={lang === 'RU' ? localeRu : localeEn}
        popupStyle={{
          zIndex: 16_000,
        }}
        value={value}
        onChange={(data) => {
          if (!handleOnlyClick) {
            handleChange(data)
          }
          setValue(() => data)
        }}
        className={classPicker ? [classPicker, Styles.rangePicker].join(' ') : Styles.rangePicker}
        showTime={{ format: 'HH:mm' }}
        format={FORMAT}
      />
      <IntervalDateComponent
        handleToday={chooseToday}
        handleWeek={chooseWeek}
        handleMonth={chooseMonth}
        handleReset={resetValue}
      />
      {children}
      <button
        className={Styles.button}
        style={{
          opacity: value ? '1' : '.5',
          cursor: value ? 'pointer' : 'default',
        }}
        disabled={!value}
        onClick={() => {
          if (value) {
            handleChange(value)
          }
        }}
      >
        {textButton}
      </button>
    </>
  )
}
