/* eslint-disable camelcase */
import React, { FC, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import type { Moment } from 'moment'
import { format } from 'date-fns'
import moment from 'moment'
import 'antd/dist/antd.css'

import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { ISectorPeriodStats } from '@app/api-types'
import { fetchStatistics } from '@app/api'

import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { ModalWindowComponent } from '@components/ModalWindow'
import { RangePickerComponent } from '@components/RangePicker'
import SVGCaretMini from '@icons/caretMiniIcon'
import NoticePopup from '../../pages/login/components/noticePopup'
import Select from '@components/Select'
import LineCharts from './line-charts'

import styles from './style.module.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { getInitialData } from '@app/redux/getInitialData'
import BarCharts from './bar-chart'
import { BASE_URL, statisticsUrl } from '@app/urls'

const FORMAT = {
  show: 'HH:mm DD.MM.YYYY',
  load: 'YYYY-MM-DD HH:mm',
}

export type IStats = {
  type: string
  data: {
    date: string
    [key: string]: number | string
  }[][]
}

export const StatisticsPage: FC = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const sectors = useAppSelector((state) => state.sectors.sectors)
  const { statisticSettings } = useAppSelector((state) => state.statistic)

  const [lang, isAuth, error] = useAppSelector((state) => [state.auth.lang, state.auth.isAuth, state.auth.error])

  const periodText = { RU: 'Период', EN: 'Time period' }
  const noPeriodText = { RU: 'Период не выбран', EN: 'Time period not selected' }
  const periodSelectionText = { RU: 'Выбор периода', EN: 'Period selection' }
  const sectorText = { RU: 'Сектор', EN: 'Sector' }
  const loadingDataText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading ...' }
  const noDataText = {
    RU: 'По данному интервалу нет данных',
    EN: 'No data available for this interval',
  }

  const [openModal, setOpenModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statsError, setStatsError] = useState(false)
  const [sectorPeriodStats, setSectorPeriodStats] = useState<IStats>(null)
  const [period, setPeriod] = useState<Moment[]>([moment().startOf('day'), moment()])
  const [sectorId, setSectorId] = useState<number | null>(null)
  const [shownListSectors, setShownListSectors] = React.useState(false)
  const [selectedSector, setSelectedSector] = React.useState<string>('')

  const getStringFromDate = (date: Moment) => {
    const newDate = moment()
      .set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: date.hour(),
        minute: date.minute(),
        second: date.second(),
      })
      .toDate()
    return format(newDate, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`)
  }

  const getStatsdata = (data: ISectorPeriodStats) => {
    const lineStatsdata: { date: string; [key: string]: number | string }[] = []
    const barStatsdata: { date: string; [key: string]: number | string }[] = []
    let dataType: string
    Object.keys(data).forEach((key) => {
      data[key].sectors.forEach((sector) => {
        if (sector.type === 'SINGLE') {
          sector.subsectors.forEach((subsector) => {
            lineStatsdata.push({
              date: key.length > 10 ? moment(key).format('HH:mm') : moment(key).format('DD.MM'),
              [subsector.type]: subsector.count,
            })
            barStatsdata.push({
              date: key.length > 10 ? moment(key).format('HH:mm') : moment(key).format('DD.MM'),
              [subsector.type]: subsector.error_count,
            })
          })
          dataType = sector.type
        } else {
          lineStatsdata.push({
            date: key.length > 10 ? moment(key).format('HH:mm') : moment(key).format('DD.MM'),
            [sector.subsectors[0].type]: sector.subsectors[0].count,
            [sector.subsectors[1].type]: sector.subsectors[1].count,
          })
          barStatsdata.push({
            date: key.length > 10 ? moment(key).format('HH:mm') : moment(key).format('DD.MM'),
            [sector.subsectors[0].type]: sector.subsectors[0].error_count,
            [sector.subsectors[1].type]: sector.subsectors[1].error_count,
          })
          dataType = sector.type
        }
      })
    })

    return {
      type: dataType,
      data: [lineStatsdata, barStatsdata],
    }
  }

  const getStatistics = useCallback(() => {
    setOpenModal(false)
    setStatsError(false)
    if (period[0] && period[1]) {
      setIsLoading(true)
      fetchStatistics(getStringFromDate(period[0]), getStringFromDate(period[1]), [sectorId])
        .then((res: ISectorPeriodStats) => {
          setSectorPeriodStats(Object.keys(res).length > 0 ? getStatsdata(res) : null)
        })
        .catch((error) => {
          console.error('Error fetching statistics:', error)
          setStatsError(true)
        })
        .finally(() => setIsLoading(false))
    }
  }, [period, sectorId])

  const fetchStatisticsReport = async (start, end) => {
    const params = new URLSearchParams({
      search: JSON.stringify({
        from: start,
        till: end,
      }),
    })
    try {
      const response = await fetch(`${BASE_URL}${statisticsUrl}?${params}`, {
        headers: {
          Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      if (!response.ok) {
        throw new Error(`Failed to fetch the Excel file. Status ${response.status}`)
      }

      const { headers } = response
      const contentDisposition = headers.get('Content-Disposition')
      const filename = decodeURIComponent(contentDisposition).split('filename*=')[1].split("''")[1]

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error report statistics:', error)
    }
  }

  const changeSelectesector = (value: string) => {
    setSectorId(Number(value))
    setSelectedSector(`${sectorText[lang]} ${value}`)
    setShownListSectors(!shownListSectors)
  }

  useEffect(() => {
    if (isAuth && sectors.length > 0) {
      setSectorId(sectors[0].id)
      setSelectedSector(`${sectorText[lang]} 1`)
    }
  }, [isAuth, sectors, lang])

  useEffect(() => {
    if (isAuth && period.length && sectorId) {
      getStatistics()
    }
  }, [period, sectorId, isAuth])

  useEffect(() => {
    if (statisticSettings) {
      fetchStatisticsReport(statisticSettings.dateStart, statisticSettings.dateEnd)
    }
  }, [statisticSettings])

  useLayoutEffect(() => {
    sectors.length > 0 ? sessionStorage.setItem('pathName', pathname) : getInitialData({ dispatch, history })
  }, [sectors])

  return (
    <>
      <div className={styles.mainContainer}>
        <Header />
        <NavigateMenu />
        <div className={styles.scrolledContainer}>
          <div className={styles.statContainer}>
            <div className={styles.wrapper}>
              <div className={styles.time}>
                {period.length
                  ? `${periodText[lang]}: ${
                      period[0] && period[1]
                        ? `${period[0].format(FORMAT.show)} - ${period[1].format(FORMAT.show)}`
                        : ''
                    }`
                  : `${noPeriodText[lang]}`}
              </div>
              <div className={styles.flexWrapper}>
                <div className={styles.types}>
                  <Select
                    isOpen={shownListSectors}
                    itemArray={sectors.map((it) => ({ id: it.id, value: `${sectorText[lang]} ${it.id}` }))}
                    selectedItem={selectedSector}
                    changeSelectedItem={changeSelectesector}
                    onClick={() => setShownListSectors(!shownListSectors)}
                  />
                </div>
                <div onClick={() => setOpenModal(true)} className={styles.period}>
                  <p>{periodSelectionText[lang]}</p>
                  <SVGCaretMini />
                </div>
                <ModalWindowComponent open={openModal} handleClose={() => setOpenModal(false)}>
                  <RangePickerComponent handleChange={setPeriod} />
                </ModalWindowComponent>
              </div>
            </div>
            {sectorPeriodStats && !isLoading && !statsError ? (
              <>
                <LineCharts data={sectorPeriodStats.data[0]} type={sectorPeriodStats.type} />
                <BarCharts data={sectorPeriodStats.data[1]} type={sectorPeriodStats.type} />
              </>
            ) : (
              <div className={styles.noDataTextWrap}>
                <h2 className={styles.noDataText}>{isLoading ? `${loadingDataText[lang]}` : `${noDataText[lang]}`}</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      {error && <NoticePopup />}
    </>
  )
}
