import React, { SyntheticEvent, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useAppSelector, useAppDispatch } from '@app/redux/hooks'

import { setLanguage } from '@app/redux/loginSlice'

import { loginUrl } from '@app/urls'
import { fetchSaveTableData, fetchTableData, fetchUpdateSettingsShift } from '@app/api'
import { getOptions } from '@app/redux/optionsSlice'

import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { ModalWindowComponent } from '@components/ModalWindow'

import { SelectLanguage } from './selectLanguage'

import 'antd/dist/antd.css'
import styles from './style.module.scss'
import { OptionTable, SaveOptionTable, SaveOptionsTable } from '@app/api-types'
import { DataType, OptionsTable } from './options-table'
import moment from 'moment'
import { getInitialData } from '@app/redux/getInitialData'

export const OptionsPage: React.FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const sectors = useAppSelector((state) => state.sectors.sectors)

  const [lang, isAuth] = useAppSelector((state) => [state.auth.lang, state.auth.isAuth])

  const sectionHeaderText = { RU: 'Период смен', EN: 'Shift periods' }
  const sectionSubheaderTextBegin1 = { RU: 'Начало смены 1', EN: '1 shift start time' }
  const sectionSubheaderTextBegin2 = { RU: 'Начало смены 2', EN: '2 shift start time' }
  const sectionSubheaderTextEnd1 = { RU: 'Конец смены 1', EN: '1 shift end time' }
  const sectionSubheaderTextEnd2 = { RU: 'Конец смены 2', EN: '2 shift end time' }
  const typeText = { RU: 'ч/м', EN: 'hh/mm' }
  const saveSettingsText = { RU: 'Сохранить настройки', EN: 'Save settings' }
  const saveModalText = { RU: 'Изменения сохранены успешно', EN: 'Changes saved successfully' }
  const loadingText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading...' }

  const { dataOptions } = useAppSelector((state) => state.options)

  const [updateOptions, setUpdateOptions] = useState(false)
  const [optionLang, setOptionLang] = useState(lang)
  const [options, setOptions] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [checkStatus] = useState(() => {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    return !userInfo?.roles?.length ?? false
  })
  const [tableData, setTabledata] = useState<DataType[]>([])
  const [tableReferences, setTableReferences] = useState<OptionTable[]>([])
  const [disabled, setDisabled] = React.useState<boolean>(true)
  const [touched, setTouched] = React.useState<boolean>(false)
  const toggleLang = (lang: 'RU' | 'EN') => {
    setOptionLang(() => lang)
  }

  const getTableData = async () => {
    try {
      const response = await fetchTableData()
      setTableReferences(response)
    } catch (error) {
      console.error('Error fetching table Data:', error)
    }
  }

  const handlerChangeTime = (event) => {
    const { name, value } = event.target

    const shifts = options.shifts.map((shift) => {
      if (shift.shift_id === Number(name)) {
        return { ...shift, start_at_time: `${value}:00` }
      } else {
        return shift
      }
    })
    setTouched(true)
    setOptions(() => ({ ...options, shifts }))
  }

  const saveTableData = async (data: SaveOptionsTable) => {
    try {
      const response = await fetchSaveTableData(data)
      if (response.status === 204) {
        getTableData()
      }
    } catch (error) {
      console.error('Error fetching saveTableData:', error)
    }
  }

  const getSaveTableData = useCallback(
    (tableData: DataType[]) => {
      const result: SaveOptionTable[] = tableData.map((item) => ({
        sector_id: item.sector,
        lots: item.lots,
        colors: item.colors,
        date: moment(item.date).format('YYYY-MM-DD'),
      }))
      return result
    },
    [tableData],
  )

  const saveOptions = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault()
      if (tableData.length > 0) {
        const saveData = getSaveTableData(tableData)
        saveTableData({ references: saveData })
      }
      setUpdateOptions((prev) => !prev)

      if (optionLang !== lang) {
        dispatch(setLanguage(optionLang === 'RU' ? 'ru-ru' : 'en-us'))
      }

      const shiftsDelta = []

      if (dataOptions !== options) {
        if (dataOptions.shifts !== options.shifts) {
          options.shifts.forEach((shift, i) => {
            if (shift !== dataOptions.shifts[i]) {
              shiftsDelta.push(shift)
            }
          })
        }
      }

      if (shiftsDelta.length > 0) {
        try {
          await Promise.all(
            shiftsDelta.map((shift) => {
              return fetchUpdateSettingsShift(shift)
            }),
          )
        } catch (e) {
          history.push(loginUrl)
        }
      }

      setOpenModal(true)

      setTimeout(() => {
        setOpenModal(false)
        setTabledata([])
        setTouched(false)
      }, 2000)
    },
    [optionLang, lang, dataOptions, options, tableData],
  )

  useEffect(() => {
    if (
      tableData.every((item) => {
        return Object.values(item).every((el) => {
          if (typeof el === 'string') {
            return el !== ''
          } else if (typeof el === 'number') {
            return el !== null
          } else if (Array.isArray(el)) {
            return el.length > 0
          }
        })
      })
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [tableData])

  useEffect(() => {
    isAuth && getTableData()
  }, [sectors, isAuth])

  useEffect(() => {
    isAuth && dispatch(getOptions())
  }, [updateOptions, isAuth])

  useEffect(() => {
    setOptions(dataOptions)
  }, [dataOptions])

  useLayoutEffect(() => {
    sectors.length > 0 ? sessionStorage.setItem('pathName', pathname) : getInitialData({ dispatch, history })
  }, [sectors])

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {options ? (
          <form className={styles.form} onSubmit={saveOptions}>
            <div className={styles.section}>
              <p className={styles.sectionHeader}>{sectionHeaderText[lang]}</p>
              <div className={styles.parameters}>
                <div className={styles.param}>
                  <span className={styles.sectionSubheader}>{sectionSubheaderTextBegin1[lang]}</span>
                  <div className={styles.inputSection}>
                    <input
                      value={options.shifts[0].start_at_time}
                      onChange={handlerChangeTime}
                      name={options.shifts[0].shift_id}
                      step='900'
                      type='time'
                      className={styles.value}
                      disabled={checkStatus}
                    />
                    <div className={styles.type}>{typeText[lang]}</div>
                  </div>
                </div>
                <div className={styles.param}>
                  <span className={styles.sectionSubheader}>{sectionSubheaderTextBegin2[lang]}</span>
                  <div className={styles.inputSection}>
                    <input
                      value={options.shifts[1].start_at_time}
                      onChange={handlerChangeTime}
                      name={options.shifts[1].shift_id}
                      step='900'
                      type='time'
                      className={styles.value}
                      disabled={checkStatus}
                    />
                    <div className={styles.type}>{typeText[lang]}</div>
                  </div>
                </div>
                <div className={styles.param}>{/* СМЕНА 3 */}</div>
                <div className={styles.param}>
                  <span className={styles.sectionSubheader}>{sectionSubheaderTextEnd1[lang]}</span>
                  <div className={styles.inputSection}>
                    <input
                      value={options.shifts[1].start_at_time}
                      onChange={handlerChangeTime}
                      name={options.shifts[0].shift_id}
                      step='900'
                      type='time'
                      className={styles.value}
                      disabled
                    />
                    <div className={styles.type}>{typeText[lang]}</div>
                  </div>
                </div>
                <div className={styles.param}>
                  <span className={styles.sectionSubheader}>{sectionSubheaderTextEnd2[lang]}</span>
                  <div className={styles.inputSection}>
                    <input
                      value={options.shifts[0].start_at_time}
                      onChange={handlerChangeTime}
                      name={options.shifts[1].shift_id}
                      step='900'
                      type='time'
                      className={styles.value}
                      disabled
                    />
                    <div className={styles.type}>{typeText[lang]}</div>
                  </div>
                </div>
                <div className={styles.param}>{/* СМЕНА 3 */}</div>
              </div>
            </div>
            <SelectLanguage handleChangeLang={toggleLang} />
            <OptionsTable data={tableReferences} setTabledata={setTabledata} />
            <button
              disabled={tableData.length === 0 && !touched ? optionLang === lang : disabled}
              type='submit'
              className={styles.button}
            >
              {/* <button disabled={optionLang === lang || disabled} type='submit' className={styles.button}> */}
              {saveSettingsText[lang]}
            </button>
            <ModalWindowComponent open={openModal} handleClose={() => setOpenModal(false)}>
              <p
                style={{
                  margin: '0',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {saveModalText[lang]}
              </p>
            </ModalWindowComponent>
          </form>
        ) : (
          <div className={styles.sectionHeader}>{loadingText[lang]}</div>
        )}
      </div>
    </div>
  )
}
