import React from 'react'

const SVGConveyorFilter: React.FC = () => {
  return (
    <svg width='36' height='35' viewBox='0 0 36 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.24097 4.375H32.4076L20.741 18.1708V30.625L14.9076 27.7083V18.1708L3.24097 4.375Z'
        stroke='#626E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGConveyorFilter
