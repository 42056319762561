import React, { useMemo, useState, useEffect, useRef, useLayoutEffect, SyntheticEvent } from 'react'
import ScrollArea from 'react-scrollbar'

import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { useAppDispatch, useAppSelector } from '../../app/redux/hooks'
import SVGConveyorFilter from '@icons/conveyor__filter'
import { SwitcherComponent } from '@components/Switcher'
import styles from './style.module.scss'
import { subscribeStomp, unsubscribeStomp } from '@app/redux/wsSlice'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getTimeWithDate } from '@helpers/getTimeWithDate'
import PopperComponent from '@components/Poper'
import cn from 'classnames'
import moment from 'moment'
import { getInitialData } from '@app/redux/getInitialData'
import { ItemInformationAboutFabric } from './components/ItemInformationAboutFabric'

export interface IFilterProps {
  // A: boolean
  // B: boolean
  // C: boolean
  all: boolean
  // nodDefects?: boolean
}

type Defect = 'all' // | 'A' | 'B' | 'C' | 'nodDefects'

interface IConveyorParams {
  id: string
}

export const ConveyorPage: React.FC = () => {
  const { pathname } = useLocation()
  const history = useHistory()
  const dispatch = useAppDispatch()

  const sectorId = Number(useParams<IConveyorParams>().id) ?? 0

  const lang: string = useAppSelector((state) => state.auth.lang)
  const tz: string = useAppSelector((state) => state.auth.tz)

  const { connectionState, sector, activeView } = useAppSelector((state) => state.webSocket)
  const sectors = useAppSelector((state) => state.sectors.sectors)
  const historyStack = useAppSelector((state) => state.webSocket.historyStack)

  const [views, setViews] = useState<string[]>([])
  const dataRequestsInterval = useRef(null)

  const [isPaused, setIsPaused] = useState(false)

  const [eyeCheck, setEyeCheck] = useState<boolean>(false)
  const [filterCheck, setFilterCheck] = useState<boolean>(false)
  const [eyeFilters, setEyeFilters] = useState<IFilterProps>({
    all: true,
  })
  const [currentDateTime, setCurrentDateTime] = useState<string>(getTimeWithDate(tz))

  const filtersText = { RU: 'Отображать все/только неуспешные', EN: 'Show all/only unsuccessful ones' }
  const pausedText = { RU: 'СТОИТ ПАУЗА', EN: 'PAUSED' }
  const timeText = { RU: 'Время', EN: 'Time' }
  const sectorText = { RU: 'Сектор', EN: 'Sector' }
  const lotText = { RU: 'Лот', EN: 'Lot' }
  const statusText = { RU: 'Статус', EN: 'Status' }
  const noDataText = { RU: 'Нет данных', EN: 'No data' }

  const setDataRequests = async () => {}

  const toggleFilterEye = (category: Defect, filter: IFilterProps) => {
    const newFilter: IFilterProps = {
      all: filter.all,
    }
    if (category === 'all') {
      newFilter.all = !filter.all
    }

    setEyeFilters(() => newFilter)
  }

  const getPauseButtonText = () => {
    const resumeText = { RU: 'Убрать паузу', EN: 'Resume' }
    const pauseText = { RU: 'Пауза', EN: 'Pause' }
    return isPaused ? resumeText[lang] : pauseText[lang]
  }

  const onPauseButton = () => {
    setIsPaused((prev: boolean) => {
      return !prev
    })
  }

  // Контент для фильтра
  const contentPoperEye = useMemo(
    () => (
      <ul className={styles.popper__container}>
        <li className={styles.popper__item}>
          <SwitcherComponent
            callback={() => toggleFilterEye('all', eyeFilters)}
            width={38}
            height={20}
            status={eyeFilters.all}
          />
          {filtersText[lang]}
        </li>
      </ul>
    ),
    [eyeFilters],
  )

  // Информация об иконках
  const informationIcons = useMemo(
    () => [
      {
        id: 2,
        name: 'filter',
        Icon: SVGConveyorFilter,
        check: filterCheck,
        callback: () => {
          setFilterCheck((prevValue) => !prevValue)
          setEyeCheck(() => false)
        },
        content: contentPoperEye,
      },
    ],
    [eyeCheck, filterCheck, contentPoperEye],
  )

  useEffect(() => {
    // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
    sector && sector.id !== sectorId && connectionState === 1 && dispatch(unsubscribeStomp())
    !isPaused && sectors && connectionState === 1 && dispatch(subscribeStomp(sectors[sectorId - 1]))
    return () => {
      dispatch(unsubscribeStomp())
    }
  }, [sectorId, sectors, connectionState])

  useEffect(() => {
    const keys = Object.keys(activeView)
    keys.sort((x, y) => y.localeCompare(x))
    setViews(keys)
  }, [activeView])

  useEffect(() => {
    isPaused && sector && connectionState === 1 && dispatch(unsubscribeStomp(true))
    !isPaused && sectors && connectionState === 1 && dispatch(subscribeStomp(sectors[sectorId - 1]))
    const intervalId = setInterval(() => {
      if (!isPaused) {
        setCurrentDateTime(getTimeWithDate(tz))
      }
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [isPaused, tz])

  useLayoutEffect(() => {
    sectors.length > 0 ? sessionStorage.setItem('pathName', pathname) : getInitialData({ dispatch, history })
  }, [sectors])

  return (
    <div
      onClick={() => {
        setEyeCheck(() => false)
        setFilterCheck(() => false)
      }}
      className={styles.component}
    >
      <Header />
      <NavigateMenu />
      <div className={styles.main__container}>
        <section className={`${styles.photos__container}`}>
          <div className={cn(styles.photo__upper, isPaused && styles.photo__upper_paused)}>
            <p>{pausedText[lang]}</p>
          </div>
          <div className={styles.photo__lower}>
            {views.length > 0
              ? views.map((view) => (
                  <div key={view} className={styles.photo__container}>
                    <img className={styles.image} src={activeView[view]?.default} />
                  </div>
                ))
              : null}
          </div>
        </section>
        <section className={styles.information__container}>
          <div className={styles.icons__container}>
            <button className={styles.pause__button} onClick={onPauseButton}>
              {getPauseButtonText()}
            </button>
            <p className={styles.time}>{currentDateTime}</p>
            <div className={styles.icons__inner}>
              {informationIcons.map(({ Icon, id, name, callback, check, content }) => (
                <div key={id}>
                  <button
                    onClick={(ev: SyntheticEvent) => {
                      callback()
                      ev.stopPropagation()
                    }}
                    id={name}
                  >
                    <Icon />
                  </button>
                  {callback && check && content ? (
                    <PopperComponent content={content} idElement={name} check={check} />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
          <ScrollArea>
            <ul className={styles.information__list}>
              {historyStack.length > 0
                ? historyStack.map((item, index) => (
                    <ItemInformationAboutFabric
                      key={index}
                      active={index < views.length}
                      datetime={moment.parseZone(item.data.created_at).format('HH:mm:ss')}
                      lot={item.data.lot}
                      sector={sectorId}
                      status={item.data.status}
                      image={item.icon}
                      callback={null}
                    />
                  ))
                : null}
            </ul>
          </ScrollArea>
        </section>
      </div>
    </div>
  )
}
