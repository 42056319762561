/* eslint-disable camelcase */
import { milliseconds } from 'date-fns'

interface Shift {
  shift_id: number
  name: string
  start_at: string
  start_at_time: string
}

export const calculateShiftTime = (shiftId: number, shifts: Shift[]) => {
  const startTime = shifts.find((shift) => shift.shift_id === shiftId).start_at.split(':')
  const longTimeMilliseconds = milliseconds({ days: 1 }) / shifts.length
  return {
    startHours: +startTime[0],
    startMinutes: +startTime[1],
    startsSeconds: +startTime[2],
    longTimeMilliseconds,
  }
}
