import React from 'react'
import styles from '../style.module.scss'
import { Product } from '@app/redux/archive-slice'
import { BASE_URL, iconDataUrl, imagesUrl } from '@app/urls'
import moment from 'moment'
interface ProductListProps {
  products: Product[]
  handleImageClick: (id: number, imageId: number) => void
}
export const ProductList = ({ products, handleImageClick }: ProductListProps) => {
  return (
    <ul className={styles.list}>
      {products.map((item) => (
        <li key={item.id} className={styles.image}>
          <img
            src={`${BASE_URL}${imagesUrl}/${item.detection.stored_image_id}${iconDataUrl}`}
            alt='image'
            onClick={() => handleImageClick(item.id, item.detection.stored_image_id)}
          />
          <div className={styles.item__scale} style={{ backgroundColor: item.status === 0 ? 'green' : 'red' }} />
          <p className={styles.image__text}>{item.lot}</p>
          <p className={styles.image__text}>{moment.parseZone(item.created_at).format('HH:mm:ss')}</p>
        </li>
      ))}
    </ul>
  )
}
