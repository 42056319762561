// eslint-disable-next-line camelcase
export const USER_ROlES = {
  user: 'firstUser', // id = 1
  admin: 'Admin', // id = 2
  secondUser: 'secondUser', // id = 3
}
export const LOGIN = {
  login: 'Логин',
  password: 'Пароль',
  button: 'Войти',
}
