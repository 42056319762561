/* eslint-disable camelcase */

import axios, { AxiosResponse } from 'axios'
import { IHistoryData, IOptions, ISearch, IShift, PagingSearch, SaveOptionsTable } from './api-types'
import {
  BASE_URL,
  colorsUrl,
  historyPagingUrl,
  historyUrl,
  imagesUrl,
  languageUrl,
  listUrl,
  loginUrl,
  logoutUrl,
  meUrl,
  productSpecsUrl,
  sectorsUrl,
  settingsUrl,
  statisticsUrl,
  tableDataUrl,
  tableSaveDataUrl,
  timezoneUrl,
  wsTokenUrl,
} from './urls'

export const isUserInfo = Boolean(sessionStorage.getItem('userInfo'))

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  validateStatus: (status) => true, // eslint-disable-line @typescript-eslint/no-unused-vars
})

const request = async ({ url, method = 'get', params, data }: IOptions): Promise<any> => {
  const response: AxiosResponse = await instance({ method, url, params, data })
  if (response.status === 200 || response.status === 204) {
    switch (url) {
      case loginUrl:
        return response
      case tableSaveDataUrl:
        return response
      case statisticsUrl:
        return response.data
      default:
        return response.data
    }
  } else {
    const error = {
      status: response.status,
      statusText: response.statusText,
      cause: response.config,
    }
    throw error
  }
}

export const fetchLogin = async (data: { user: string; pass: string }) => {
  const formData = new FormData()
  formData.append('user', data.user)
  formData.append('pass', data.pass)

  return await request({ url: loginUrl, method: 'post', data: formData })
}

export const fetchMe = () => request({ url: meUrl })

export const fetchLogout = () => request({ url: logoutUrl })

export const fetchSettings = async () => request({ url: settingsUrl })

export const fetchTableData = async () => request({ url: tableDataUrl })

export const fetchLanguage = async () => request({ url: languageUrl })

export const fetchUpdateLanguage = async (code: string) => request({ url: languageUrl, method: 'put', data: { code } })

export const fetchTimezone = async () => request({ url: timezoneUrl })

export const fetchSectors = async (id?: string) =>
  request({ url: id === undefined ? `${sectorsUrl}` : `${sectorsUrl}/${id}` })

export const fetchSpecs = async (type: 'lot' | 'weight') => request({ url: productSpecsUrl, params: { type } })

export const fetchColors = async () => request({ url: colorsUrl })

export const fetchStatistics = async (start: string, end: string, sectorId?: number[]) => {
  return request({
    url: statisticsUrl,
    method: 'get',
    params: new URLSearchParams({
      search: JSON.stringify({
        from: start,
        till: end,
        sectors: sectorId,
      }),
    }),
  })
}

export const fetchPeriodHistory = async (historyData: IHistoryData) => {
  const { year, month, day, shift } = historyData
  let url = historyUrl

  if (year) {
    url += `/${year}/months`
    if (month) {
      url += `/${month}/days`
      if (day) {
        url += `/${day}/shifts`
        if (shift) {
          url += `/${shift}/sectors`
        }
      }
    }
  }

  return request({ url })
}

export const fetchUpdateSettingsShift = async (shift: IShift) =>
  request({ url: `${settingsUrl}/shifts/${shift.shift_id}`, method: 'put', data: shift })

export const fetchProducts = async (search: ISearch) => {
  const params = new URLSearchParams({ search: JSON.stringify(search) })
  return request({ url: listUrl, params })
}

export const fetchProductsPaging = async (search: PagingSearch) => {
  const params = new URLSearchParams({ search: JSON.stringify(search) })
  return request({ url: historyPagingUrl, params })
}

export const fetchImageById = async (id: number) => request({ url: `${imagesUrl}/${id}` })

export const fetchWsToken = async () => request({ url: wsTokenUrl, method: 'post', data: { expiration: 300 } })

export const fetchSaveTableData = (data: SaveOptionsTable) => request({ url: tableSaveDataUrl, method: 'post', data })
