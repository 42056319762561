export const BASE_URL = '/api'
export const listUrl = '/v1/products'
export const loginUrl = '/login'
export const logoutUrl = '/logout'
export const productSpecsUrl = '/v1/product-options/specs'
export const colorsUrl = '/v1/product-options/colors'
export const sectorsUrl = '/v1/cameras/sectors'
export const statisticsUrl = '/v1/stats'
export const historyUrl = '/v1/product-history/years'
export const historyPagingUrl = '/v1/products/paging'
export const settingsUrl = '/settings'
export const languageUrl = `${settingsUrl}/current-language`
export const timezoneUrl = '/settings/timezone'
export const imagesUrl = '/v1/images'
export const meUrl = '/me'
export const iconDataUrl = '/icon/data'

export const defaultDataUrl = '/default/data'

export const tableSaveDataUrl = '/v1/product-options/references/bulk'
export const tableDataUrl = '/v1/product-options/references'

export const wsTokenUrl = '/me/tokens'
export const wsBaseUrl = '/api/ws/products'
export const wsChannelsBaseUrl = '/sector'
