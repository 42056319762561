import React from 'react'
import success from '../../../../assets/svg/success.svg'
import warning from '../../../../assets/svg/warning.svg'
import styles from './style.module.scss'
import classNames from 'classnames'

const InputLogin = ({ login, placehold }) => {
  const inputWrapClassName = classNames(styles.inputWrap, {
    [styles.inputWrapWarning]: login.isDirty && login.error,
    [styles.inputWrapSuccess]: login.isDirty && !login.error,
  })
  const textErrorClassName = classNames(styles.textError, {
    [styles.textErrorWarning]: login.isDirty && login.error,
    [styles.textErrorSuccess]: login.isDirty && !login.error,
  })
  const icon = login.isDirty ? login.error ? <img src={warning} /> : <img src={success} /> : ''

  return (
    <>
      <div className={inputWrapClassName}>
        <input
          className={styles.input}
          placeholder={placehold}
          onChange={login.onChange}
          onFocus={login.onFocus}
          value={login.value}
        />
        <i className={styles.icon}>{icon}</i>
      </div>
      <span className={textErrorClassName}>{login.isDirty && login.error && login.error}</span>
    </>
  )
}

export default InputLogin
