import React, { useState } from 'react'
import { useAppSelector } from '@app/redux/hooks'
import { ModalWindowComponent } from '@components/ModalWindow'
import styles from './style.module.scss'
import SVGReport from '@icons/report'
import { SearchreportContent } from '@components/ModalWindow/search-report-content'

export const StatisticPath = () => {
  const [openModalSearch, setOpenModalSearch] = useState<boolean>(false)
  const lang: 'RU' | 'EN' = useAppSelector((state) => state.auth.lang)

  const headerText = { RU: 'Статистика', EN: 'Statistics' }

  return (
    <div className={styles.path}>
      <div className={styles.headerTitle}> {headerText[lang]}</div>
      <div
        onClick={() => {
          setOpenModalSearch(() => true)
        }}
        className={styles.pathFilter}
      >
        <SVGReport />
      </div>
      <ModalWindowComponent
        handleClose={() => {
          if (document.querySelector('.ant-picker-dropdown-hidden')) {
            setOpenModalSearch(() => false)
          }
          if (!document.querySelector('div[aria-labelledby="modal-modal-title"]+div')) {
            setOpenModalSearch(() => false)
          }
        }}
        open={openModalSearch}
      >
        <SearchreportContent
          modalCallback={() => {
            setOpenModalSearch(() => false)
          }}
        />
      </ModalWindowComponent>
    </div>
  )
}
