import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useInput } from '../hooks/useInput'
import InputLogin from '../inputLogin'
import InputPassword from '../inputPassword'
import Button from '../button'
import NoticePopup from '../noticePopup'
import BlockAction from '../blockAction'
import styles from './style.module.scss'

import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { loginUser } from '@app/redux/loginSlice'
import * as CONST from '../../../../global-constants'
import { getInitialData } from '@app/redux/getInitialData'

const LoginForm: React.FC = () => {
  const [lang, isAuth] = useAppSelector((state) => [state.auth.lang, state.auth.isAuth])

  const [showError, setShowError] = React.useState<boolean>(false)

  const dispatch = useAppDispatch()
  const history = useHistory()
  const { isLoading, error } = useAppSelector((state) => state.auth)
  const login = useInput('', { isEmpty: true, isLogin: true })
  const password = useInput('', { isEmpty: true, isPassword: true })

  const validation = () => !(login.error || password.error)

  useEffect(() => {
    if (error) {
      setShowError(() => true)
      setTimeout(() => {
        setShowError(() => false)
      }, 3000)
    }
  }, [error])

  const handleSubmit = async (e) => {
    e.preventDefault()
    login.value && password.value && !isAuth && dispatch(loginUser({ user: login.value, pass: password.value }))
  }

  useEffect(() => {
    isAuth && getInitialData({ dispatch, history, tryPath: '/conveyor/1' })
  }, [isAuth])

  return (
    <>
      <form className={styles.component} onSubmit={handleSubmit} noValidate>
        <InputLogin login={login} placehold={CONST.LOGIN.login} />
        <InputPassword password={password} placehold={CONST.LOGIN.password} />
        <Button name={lang === 'RU' ? CONST.LOGIN.button : 'Login'} valid={validation()} isLoading={isLoading} />
      </form>
      {isLoading && <BlockAction />}
      {showError && <NoticePopup />}
    </>
  )
}

export default LoginForm
