import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'

import { useAppSelector } from '@app/redux/hooks'

import { fetchPeriodHistory } from '@app/api'
import { loginUrl } from '@app/urls'

import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'

import SVGFolder from '@icons/folder'
import styles from '../style.module.scss'

interface IRouterParams {
  year: string
}

export const YearPage: React.FC = ({ match }: any) => {
  const lang: string = useAppSelector((state) => state.auth.lang)
  const loadYearText = { RU: 'За данный год данные отсутствуют', EN: 'No data available for this year' }
  const loadNoDataText = { RU: 'Данные отсутствуют', EN: 'No data available' }

  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()

  React.useEffect(() => {
    fetchPeriodHistory({ year: Number(year) })
      .then((res: Array<number>) => {
        return match.params.year === currentDate.getFullYear().toString() ? res.slice(0, currentMonth + 1) : res
      })
      .then((res: Array<number>) => {
        setMonths(
          res
            .sort((a, b) => a - b)
            .map((m) => m.toString())
            .reverse(),
        )
        setShowMonths(() => true)
        setLoadText(() => loadYearText[lang])
      })
      .catch(() => {
        history.push(loginUrl)
      })
  }, [])

  const history = useHistory()

  const { year } = useParams<IRouterParams>()

  const [showMonths, setShowMonths] = React.useState<boolean>(false)
  const [loadText, setLoadText] = React.useState<string>(loadNoDataText[lang])

  const [months, setMonths] = React.useState<Array<string>>([
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ])

  const nameMonthRus = React.useMemo(
    () => [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    [],
  )

  const nameMonthEn = React.useMemo(
    () => [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    [],
  )

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {showMonths ? (
          months?.map((month) => {
            const transformMonth = month.startsWith('0') ? month.split('')[1] : month
            return (
              <Link to={`/image-base/${match.params.year}/${transformMonth}`} key={month} className={styles.folder}>
                <SVGFolder />
                <p>{lang === 'RU' ? nameMonthRus[+month - 1] : nameMonthEn[+month - 1]}</p>
              </Link>
            )
          })
        ) : (
          <p className={styles.load_text}>{loadText}</p>
        )}
      </div>
    </div>
  )
}
