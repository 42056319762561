import React from 'react'
import styles from './style.module.scss'
import { useAppSelector } from '@app/redux/hooks'

const NoticePopup: React.FC = () => {
  const { error, lang } = useAppSelector((state) => state.auth)

  const error401Text = {
    RU: 'Ошибка авторизации, введены неверные данные',
    EN: 'Authorization error, incorrect data entered',
  }
  const error504Text = {
    RU: 'Ошибка на сервере. Попробуйте зайти позже',
    EN: 'Server error. Please try again later',
  }
  const error422Text = {
    RU: 'Неправильный запрос',
    EN: 'Invalid request',
  }
  const error403Text = {
    RU: 'Нет прав на операцию',
    EN: 'No permission to operate',
  }

  const textError = React.useMemo(() => {
    const errorText = error.split(' ')
    const code = Number(errorText[errorText.length - 1])
    if (code) {
      switch (code) {
        case 401:
          return error401Text[lang]
        case 500:
        case 502:
        case 504:
          return error504Text[lang]
        case 400:
        case 422:
          return error422Text[lang]
        case 403:
          return error403Text[lang]
      }
    }
  }, [error])

  return (
    <div className={styles.component}>
      <div className={styles.container}>
        <p className={styles.error}>{textError}</p>
      </div>
    </div>
  )
}

export default NoticePopup
