import { useState } from 'react'
import { useValidation } from './useValidation'

export const useInput = (initValue, validations) => {
  const [value, setValue] = useState(initValue)
  const [isDirty, setDirty] = useState(false)

  const error = useValidation(value, validations)

  const onChange = (e) => {
    setValue(e.target.value)
  }

  const onFocus = () => {
    setDirty(true)
  }

  return {
    value,
    onChange,
    onFocus,
    isDirty,
    error,
  }
}
