import React from 'react'

const SVGFilterHeader = () => (
  // <svg xmlns='http://www.w3.org/2000/svg' width='37' height='33' viewBox='0 0 37 33'>
  //   <path
  //     d='M.57 1.37A1.51 1.51 0 0 1 1.93.5H35.27a1.51 1.51 0 0 1 1.36.87A1.49 1.49 0 0 1 36.41 3l-13 15.35V32a1.5 1.5 0 0 1-2.18 1.34L14.6 30a1.5 1.5 0 0 1-.83-1.34V18.32L.79 3A1.51 1.51 0 0 1 .57 1.37ZM5.17 3.5 16.41 16.8a1.49 1.49 0 0 1 .36 1v10l3.67 1.83V17.77a1.48 1.48 0 0 1 .35-1L32 3.5Z'
  //     transform='translate(-0.43 -0.5)'
  //     fill='#9ca6c9'
  //     fillRule='evenodd'
  //   />
  // </svg>
  <svg xmlns='http://www.w3.org/2000/svg' width='28' height='25' viewBox='0 0 28 25'>
    <path
      d='M.44 1.13a1.15 1.15 0 0 1 1-.66H26.73a1.15 1.15 0 0 1 1 .66 1.13 1.13 0 0 1-.16 1.21L17.76 14V24.34a1.14 1.14 0 0 1-1.64 1l-5.05-2.52a1.13 1.13 0 0 1-.63-1V14L.61 2.34A1.14 1.14 0 0 1 .44 1.13ZM3.92 2.75l8.52 10.07a1.12 1.12 0 0 1 .27.73v7.56l2.78 1.39V13.55a1.18 1.18 0 0 1 .27-.73L24.28 2.75Z'
      transform='translate(-0.34 -0.47)'
      fill='#9ca6c9'
      fillRule='evenodd'
    />
  </svg>
)

export default SVGFilterHeader
