import React from 'react'
import Lock from '../lock'
import styles from './style.module.scss'
import classNames from 'classnames'

type InputPasswordProps = {
  placehold: string
  password: any
}

const InputPassword: React.FC<InputPasswordProps> = ({ placehold, password }) => {
  const [showPassword, setShowPassword] = React.useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const inputWrapClassName = classNames(styles.inputWrap, {
    [styles.inputWrapWarning]: password.isDirty && password.error,
    [styles.inputWrapSuccess]: password.isDirty && !password.error,
  })
  const textErrorClassName = classNames(styles.textError, {
    [styles.textErrorWarning]: password.isDirty && password.error,
    [styles.textErrorSuccess]: password.isDirty && !password.error,
  })
  return (
    <>
      <div className={inputWrapClassName}>
        <input
          type={showPassword ? 'text' : 'password'}
          className={styles.input}
          name='password'
          placeholder={placehold}
          onChange={password.onChange}
          onFocus={password.onFocus}
          value={password.value}
        />
        <Lock showPassword={showPassword} toggleShowPassword={toggleShowPassword} />
      </div>
      <span className={textErrorClassName}>{password.isDirty && password.error ? password.error : ''}</span>
    </>
  )
}

export default InputPassword
