import React from 'react'
import { useAppSelector } from '@app/redux/hooks'
import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import { fetchPeriodHistory } from '@app/api'
import { loginUrl } from '@app/urls'
import { Link, useParams, useHistory } from 'react-router-dom'
import SVGFolder from '@icons/folder'
import styles from '../style.module.scss'

interface IRouterParams {
  year: string
  month: string
}

export const MonthPage: React.FC = ({ match }: any) => {
  const lang: string = useAppSelector((state) => state.auth.lang)

  const loadingDataText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading ...' }
  const loadNoDataText = { RU: 'Данные отсутствуют', EN: 'No data available' }

  const { year, month } = useParams<IRouterParams>()

  const [links, setLinks] = React.useState<Array<number>>([])

  const [loadText, setLoadText] = React.useState<string>(loadingDataText[lang])
  const history = useHistory()

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear().toString()
  const currentMonth = (currentDate.getMonth() + 1).toString()
  const currentDay = currentDate.getDate()

  React.useEffect(() => {
    fetchPeriodHistory({ year: +year, month: +month })
      .then((res: Array<number>) => {
        return match.params.year === currentYear && match.params.month === currentMonth ? res.slice(0, currentDay) : res
      })
      .then((res: Array<number>) => {
        setLinks(() => res.reverse())
        setLoadText(() => loadNoDataText[lang])
      })
      .catch(() => {
        history.push(loginUrl)
      })
  }, [])

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {links.length ? (
          links?.map((link) => (
            <Link
              to={`/image-base/${match.params.year}/${match.params.month}/${link}`}
              className={styles.folder}
              key={link}
            >
              <SVGFolder />
              <p>{link}</p>
            </Link>
          ))
        ) : (
          <p className={styles.load_text}>{loadText}</p>
        )}
      </div>
    </div>
  )
}
