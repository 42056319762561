import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import './library/modal.min.js'
import './index.scss'

import App from './components/app'
import { store } from '@app/redux/store'

document.documentElement.classList.add('mts-theme', 'mts-theme-light')

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
