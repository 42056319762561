/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { LineChart, Line, YAxis, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { useAppSelector } from '@app/redux/hooks'

import styles from './style.module.scss'
import { IStats } from '.'

interface ILineChartComponent {
  type: string
  data: IStats['data'][0]
  size?: { width: number; height: number }
}

const LineCharts: React.FC<ILineChartComponent> = (props) => {
  const { data, type, size = { width: 1200, height: 400 } } = props

  const { lang } = useAppSelector((state) => state.auth)

  const titleText = { RU: 'Всего продукции', EN: 'Total production' }

  const lineText = [
    { RU: 'Входящая продукция', EN: 'Incoming products' },
    { RU: 'Выходящая продукция', EN: 'Outgoing products' },
  ]

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h6 className='label'>{label}</h6>
          {payload.map((it, index) => (
            <p key={index}>{`${it.name}: ${it.value}`}</p>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <>
      {data && (
        <div className={styles.chartContainer}>
          <h2 className={styles.title}>{titleText[lang]}</h2>
          <ResponsiveContainer className={styles.responsiveContainer} width='100%' height={400}>
            <LineChart width={size.width} height={size.height} data={data}>
              <CartesianGrid strokeDasharray='2 5' />
              <XAxis dataKey='date' tickMargin={10} />
              <YAxis />
              <Tooltip content={customTooltip} />
              <Legend wrapperStyle={{ bottom: '-25px', width: '100%', display: 'flex', justifyContent: 'center' }} />
              {type === 'SINGLE' ? (
                <Line type='monotone' dataKey='MAIN' name={lineText[1][lang]} stroke='#3862FA' />
              ) : (
                <>
                  <Line type='monotone' dataKey='FINAL' name={lineText[1][lang]} stroke='#4aaee8' />
                  <Line type='monotone' dataKey='INITIAL' name={lineText[0][lang]} stroke='#3862FA' />
                </>
              )}
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  )
}

export default LineCharts
