/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import {
  LineChart,
  Line,
  YAxis,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from 'recharts'
import { useAppSelector } from '@app/redux/hooks'

import styles from './style.module.scss'
import { IStats } from '.'

interface IBarChartComponent {
  type: string
  data: IStats['data'][0]
  size?: { width: number; height: number }
}

const BarCharts: React.FC<IBarChartComponent> = (props) => {
  const { data, type, size = { width: 1200, height: 400 } } = props

  const { lang } = useAppSelector((state) => state.auth)

  const titleText = { RU: 'Ошибки', EN: 'Errors' }

  const barText = [
    { RU: 'Входящая продукция', EN: 'Incoming products' },
    { RU: 'Выходящая продукция', EN: 'Outgoing products' },
  ]

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip'>
          <h6 className='label'>{label}</h6>
          {payload.map((it, index) => (
            <p key={index}>{`${it.name}: ${it.value}`}</p>
          ))}
        </div>
      )
    }
    return null
  }

  return (
    <>
      {data && (
        <div className={styles.chartContainer}>
          <h2 className={styles.title}>{titleText[lang]}</h2>
          <ResponsiveContainer className={styles.responsiveContainer} width='100%' height={400}>
            <BarChart width={size.width} height={size.height} data={data}>
              <CartesianGrid strokeDasharray='2 5' />
              <XAxis dataKey='date' tickMargin={10} />
              <YAxis />
              <Tooltip content={customTooltip} />
              <Legend wrapperStyle={{ bottom: '-5px', width: '100%', display: 'flex', justifyContent: 'center' }} />
              {type === 'SINGLE' ? (
                <Bar type='monotone' dataKey='MAIN' name={barText[1][lang]} fill='#cc0018' />
              ) : (
                <>
                  <Bar type='monotone' dataKey='FINAL' name={barText[1][lang]} fill='#cc0018' />
                  <Bar type='monotone' dataKey='INITIAL' name={barText[0][lang]} fill='#66000c' />
                </>
              )}
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  )
}

export default BarCharts
