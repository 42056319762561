import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ISector } from '@app/api-types'
import { fetchSectors } from '@app/api'

type InitialState = {
  sectors: ISector[]
}

export const getSectors = createAsyncThunk('sectors/fetchSectors', async () => (await fetchSectors()) || [])

const initialState: InitialState = {
  sectors: [],
}

export const sectorsSlice = createSlice({
  name: 'sectors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSectors.fulfilled, (state, { payload }) => {
      state.sectors = payload
    })
  },
})

export const sectors = sectorsSlice.reducer
