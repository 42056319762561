import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import SVGHeaderBackArrow from '@icons/header-back-arrow'
import SVGFilterHeader from '@icons/filterSmall'
import SVGCaret from '@icons/caretIcon'
import { useAppSelector } from '@app/redux/hooks'
import { ModalWindowComponent } from '@components/ModalWindow'
import { Breadcrumbs } from '@components/Breadcrumbs'
import styles from './style.module.scss'
import { SearchPhotoContent } from '@components/ModalWindow/SearchPhotoContent'

export const ImageBasePath = () => {
  const [openModalSearch, setOpenModalSearch] = useState<boolean>(false)
  const lang: 'RU' | 'EN' = useAppSelector((state) => state.auth.lang)

  const filterText = { RU: 'Фильтр', EN: 'Filter' }
  const archiveText = { RU: 'Архив', EN: 'Archive' }

  const [path, setPath] = React.useState('')
  const history = useHistory()
  const { pathname } = useLocation()
  React.useEffect(() => {
    if (pathname === '/image-base') {
      setPath('')
    } else {
      setPath(() => pathname)
    }
  }, [pathname])

  const stepBackward = () => {
    const pathArray = path.split('/')
    pathArray.splice(pathArray.length - 1, 1)
    const modifyPath = pathArray.join('/')
    history.push(modifyPath)
  }

  return (
    <div className={styles.path}>
      {path ? (
        pathname === '/image-base/filter' ? (
          <div className={styles.headerTitle}>
            <div onClick={stepBackward}>
              <SVGHeaderBackArrow />
            </div>
            {filterText[lang]}
          </div>
        ) : (
          <>
            <div onClick={stepBackward}>
              <SVGHeaderBackArrow />
            </div>
            <Breadcrumbs />
          </>
        )
      ) : pathname === '/image-base/filter' ? (
        <div className={styles.headerTitle}>
          <div onClick={stepBackward}>
            <SVGHeaderBackArrow />
          </div>
          {filterText[lang]}
        </div>
      ) : (
        <div className={styles.headerTitle}> {archiveText[lang]}</div>
      )}
      <div
        onClick={() => {
          setOpenModalSearch(() => true)
        }}
        className={styles.pathFilter}
      >
        <SVGFilterHeader />
        {filterText[lang]}
        <SVGCaret />
      </div>
      <ModalWindowComponent
        handleClose={() => {
          if (document.querySelector('.ant-picker-dropdown-hidden')) {
            setOpenModalSearch(() => false)
          }
          if (!document.querySelector('div[aria-labelledby="modal-modal-title"]+div')) {
            setOpenModalSearch(() => false)
          }
        }}
        open={openModalSearch}
      >
        <SearchPhotoContent
          modalCallback={() => {
            setOpenModalSearch(() => false)
          }}
        />
      </ModalWindowComponent>
    </div>
  )
}
