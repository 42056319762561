import React from 'react'

const SVGExitAccount: React.FC = () => {
  return (
    <svg width='41' height='41' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.25 39.4143H5.91667C4.8116 39.4143 3.75179 38.9753 2.97039 38.1939C2.18899 37.4125 1.75 36.3527 1.75 35.2476V6.08097C1.75 4.9759 2.18899 3.9161 2.97039 3.13469C3.75179 2.35329 4.8116 1.91431 5.91667 1.91431H14.25'
        stroke='#3862FA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M28.8334 31.0809L39.25 20.6642L28.8334 10.2476'
        stroke='#3862FA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M39.25 20.6643H14.25' stroke='#3862FA' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export default SVGExitAccount
