import React from 'react'

const SVGReport: React.FC = () => {
  return (
    <svg width='38' height='39' viewBox='0 0 38 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.5743 3.25H21.9076L31.1576 13V32.5C31.1576 33.362 30.8328 34.1886 30.2545 34.7981C29.6763 35.4076 28.892 35.75 28.0743 35.75H9.5743C8.75655 35.75 7.97229 35.4076 7.39405 34.7981C6.81582 34.1886 6.49097 33.362 6.49097 32.5V6.5C6.49097 5.63805 6.81582 4.8114 7.39405 4.2019C7.97229 3.59241 8.75655 3.25 9.5743 3.25Z'
        stroke='#626E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M21.9075 3.25V13H31.1575'
        stroke='#626E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M24.9908 21.125H12.6575' stroke='#626E99' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M24.9908 27.625H12.6575' stroke='#626E99' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
      <path
        d='M15.7408 14.625H14.1991H12.6575'
        stroke='#626E99'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGReport
