/* eslint-disable camelcase */
import React from 'react'

import { useAppSelector } from '@app/redux/hooks'

import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'

import { fetchPeriodHistory } from '@app/api'
import { loginUrl } from '@app/urls'

import { Link, useParams, useHistory } from 'react-router-dom'

import SVGFolder from '@icons/folder'

import styles from '../style.module.scss'

interface IRouterParams {
  year: string
  month: string
  day: string
}

interface IShiftsParams {
  shift_id: number
  name: string
  start_at: string
}

export const DayPage: React.FC = ({ match }: any) => {
  const lang: string = useAppSelector((state) => state.auth.lang)
  const history = useHistory()
  const { year, month, day } = useParams<IRouterParams>()
  const [shifts, setShifts] = React.useState<Array<IShiftsParams>>([])

  const loadingDataText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading ...' }
  const loadNoDataText = { RU: 'Данные отсутствуют', EN: 'No data available' }
  const shiftText = { RU: 'Смена', EN: 'Shift' }

  const [loadText, setLoadText] = React.useState<string>(loadingDataText[lang])

  React.useEffect(() => {
    let normalMonth
    if (month.startsWith('0')) {
      normalMonth = month.split('')[1]
    }
    normalMonth = month

    fetchPeriodHistory({ year: +year, month: +normalMonth, day: +day })
      .then((res: Array<IShiftsParams>) => {
        setShifts(() => res)
        setLoadText(() => loadNoDataText[lang])
      })
      .catch(() => {
        history.push(loginUrl)
      })
  }, [])

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {shifts.length ? (
          shifts.map(({ shift_id }, index) => {
            return (
              <Link
                key={shift_id + index}
                to={`/image-base/${match.params.year}/${match.params.month}/${match.params.day}/0${shift_id}`}
                className={styles.folder}
              >
                <SVGFolder />
                <p>
                  {shiftText[lang]} 0{shift_id}
                </p>
              </Link>
            )
          })
        ) : (
          <p className={styles.load_text}>{loadText}</p>
        )}
      </div>
    </div>
  )
}
