import { useHistory } from 'react-router-dom'
import { getLanguage, getTimeZone, loginSuccess } from './loginSlice'
import { getOptions } from './optionsSlice'
import { getSectors } from './sectors-slice'
import { AppDispatchType } from './store'
import { connectStomp } from './wsSlice'
import { loginUrl } from '@app/urls'

export const getInitialData = async ({
  dispatch,
  history,
  tryPath,
  catchPath = loginUrl,
}: {
  dispatch: AppDispatchType
  history: ReturnType<typeof useHistory>
  tryPath?: string
  catchPath?: string
}) => {
  const pathName = sessionStorage.getItem('pathName')
  if (!tryPath && !pathName) {
    history.push(loginUrl)
    return
  }
  try {
    const response = await Promise.all([
      dispatch(getLanguage()).unwrap(),
      dispatch(getTimeZone()).unwrap(),
      dispatch(getOptions()).unwrap(),
      dispatch(getSectors()).unwrap(),
    ])
    dispatch(loginSuccess())
    dispatch(connectStomp())
    history && history.push(tryPath || (sessionStorage.getItem('pathName') ?? '/conveyor/1'))
  } catch (error) {
    console.error(error)
    history && history.push(catchPath)
  }
}
