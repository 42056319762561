import React from 'react'

const SVGLogo: React.FC = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='51' height='59' viewBox='0 0 51 59'>
      <g>
        <text transform='translate(0 43.45)' fontSize='48.53' fill='#1d1d1b' fontFamily='Impact'>
          f
        </text>
        <text transform='translate(14.03 43.45)' fontSize='48.53' fill='#3ba4dd' fontFamily='Impact'>
          m
        </text>
      </g>
    </svg>
  )
}

export default SVGLogo
