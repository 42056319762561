import React from 'react'
import { Popover } from '@mui/material'

interface SectorModalProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  id: string | undefined
  anchorEl: HTMLButtonElement | null
  vertical: number | 'top' | 'center' | 'bottom'
  horizontal: number | 'center' | 'left' | 'right'
}

export const SectorPopover = (props: SectorModalProps) => {
  const { open, onClose, children, id, anchorEl, vertical, horizontal } = props
  return (
    <Popover
      id={id}
      anchorEl={anchorEl}
      transformOrigin={{
        vertical,
        horizontal,
      }}
      onClose={onClose}
      open={open}
    >
      {children}
    </Popover>
  )
}
