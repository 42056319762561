import React, { useCallback, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import SVGLogo from '@icons/logo'
import { arrayRoutes } from '../../routes'
import SVGExitAccount from '@icons/exit-account'
import styles from './style.module.scss'
import { SectorPopover } from '@components/Navigation/sector-popover/sector-popover'
import SVGRealtimeConveyor from '@icons/realtime_conveyor'
import { SectorList } from '@components/Navigation/sector-list/sector-list'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { disconnectStomp, unsubscribeStomp } from '@app/redux/wsSlice'
import { logoutUser } from '@app/redux/loginSlice'

export const NavigateMenu: React.FC = () => {
  const history = useHistory()
  const params = useParams()
  const dispatch = useAppDispatch()
  const sectors = useAppSelector((state) => state.sectors.sectors)
  const { connectionState } = useAppSelector((state) => state.webSocket)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  useEffect(() => {
    if (history.location.pathname.includes('image-base')) {
      handleClose()
    }
  }, [params])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const logOut = async () => {
    if (connectionState === 1) {
      // 0 - disconnected, 1 - connected, 2 - onConnecting, 3 - onDisconnecting
      dispatch(unsubscribeStomp())
      dispatch(disconnectStomp())
      dispatch(logoutUser())
    }
    sessionStorage.removeItem('userInfo')
    sessionStorage.removeItem('pathName')
    history.push('/login')
  }

  return (
    <>
      <Box className={styles.navigateContainer}>
        <List className={styles.navigateList}>
          <ListItem className={styles.navigateItem}>
            <ListItemIcon className={styles.navigateItem}>
              {/* <button onClick={() => history.push('/')} className={styles.button}> */}
              <SVGLogo />
              {/* </button> */}
            </ListItemIcon>
          </ListItem>
          <ListItem className={styles.navigateItem}>
            <ListItemIcon className={styles.navigateItem}>
              <button onClick={handleClick} aria-describedby={id} className={styles.button}>
                <SVGRealtimeConveyor />
              </button>
            </ListItemIcon>
          </ListItem>
          {arrayRoutes.map(({ id, path, ...route }) => {
            return (
              <ListItem key={id} className={styles.navigateItem}>
                <Link to={path}>
                  <ListItemIcon className={styles.navigateItem}>
                    <route.image />
                  </ListItemIcon>
                </Link>
              </ListItem>
            )
          })}
          <ListItem className={styles.navigateItem} onClick={() => logOut()}>
            <ListItemIcon className={styles.navigateItem}>
              <SVGExitAccount />
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
      <SectorPopover id={id} anchorEl={anchorEl} open={open} onClose={handleClose} vertical='top' horizontal={-64}>
        <SectorList list={sectors} path='/conveyor' onClose={handleClose} />
      </SectorPopover>
    </>
  )
}
