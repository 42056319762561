import { fetchSettings } from '@app/api'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getOptions = createAsyncThunk('options/getOptions', async () => {
  try {
    const response = await fetchSettings()
    return response
  } catch (err) {
    console.log(err)
  }
})

/* eslint-disable */
export interface IOptions {
  /// TODO: удалить
  /// defect_categories: DefectCategory[]
  language: Language
  languages: Language[]
  shifts: Shift[]
}

interface Size {
  x: number
  y: number
}

interface Language {
  code: string
}

interface IColor {
  red: number
  green: number
  blue: number
  alpha: number
}

interface Shift {
  shift_id: number
  name: string
  start_at: string
  start_at_time: string
}
/* eslint-enable */

interface IInitialState {
  message: string
  dataOptions: IOptions
}

const initialState: IInitialState = {
  message: '',
  dataOptions: null,
}

export const optionsSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    setMessage: (state, { payload }) => {
      state.message = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptions.pending, (state) => {
      state.message = 'loading'
    })
    builder.addCase(getOptions.fulfilled, (state, { payload }) => {
      state.dataOptions = payload
      state.message = 'success'
    })
    builder.addCase(getOptions.rejected, (state) => {
      state.message = 'error'
    })
  },
})

export const { setMessage } = optionsSlice.actions

export default optionsSlice.reducer
