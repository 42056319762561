import React, { SyntheticEvent } from 'react'
import { useAppSelector } from '@app/redux/hooks'

import styles from './style.module.scss'

interface IItemProps {
  active: boolean
  datetime: string
  lot: string
  sector: number
  status: number
  image: string
  callback: (ev: SyntheticEvent) => void
}

export const ItemInformationAboutFabric: React.FC<IItemProps> = (props) => {
  const { active, datetime, lot, sector, status, image, callback } = props
  const lang: string = useAppSelector((state) => state.auth.lang)
  const timeText = { RU: 'Время', EN: 'Time' }
  const lotText = { RU: 'Лот', EN: 'Lot' }
  const sectorText = { RU: 'Сектор', EN: 'Sector' }
  const statusText = { RU: 'Статус', EN: 'Status' }

  return (
    <li
      style={{
        background: active ? '#EDF3FF' : '#FBFCFE',
      }}
      onClick={callback}
      className={styles.item}
    >
      <div
        style={{
          background: status === 0 ? 'green' : 'red',
        }}
        className={styles.item__scale}
      />
      <img src={image} className={styles.item__image} alt='Photo__mini' />
      <section className={styles.generalInformation__container}>
        <p className={styles.generalInformation__date}>
          {timeText[lang]}: {datetime}
        </p>
        <p className={styles.generalInformation__lot}>
          {lotText[lang]}: {lot}
        </p>
        <p className={styles.generalInformation__sector}>
          {sectorText[lang]}: {sector}
        </p>
        <p className={styles.generalInformation__status}>
          {statusText[lang]}: {status}
        </p>
      </section>
    </li>
  )
}
