import React from 'react'

const SVGFolder = () => {
  return (
    <svg width='138' height='125' viewBox='0 0 138 125' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M133.017 119.997C135.567 117.434 137 113.958 137 110.333V35.1667C137 31.542 135.567 28.0659 133.017 25.5029C130.466 22.9399 127.007 21.5 123.4 21.5H62.2L48.6 1H14.6C10.9931 1 7.53384 2.43988 4.98335 5.00287C2.43285 7.56587 1 11.042 1 14.6667V110.333C1 113.958 2.43285 117.434 4.98335 119.997C7.53384 122.56 10.9931 124 14.6 124H123.4C127.007 124 130.466 122.56 133.017 119.997Z'
        stroke='#3862FA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default SVGFolder
