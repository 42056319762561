import React from 'react'
import lock from '../../../../assets/svg/eyeLock.svg'
import unlock from '../../../../assets/svg/eyeUnLock.svg'
import styles from './style.module.scss'

const Lock = ({ showPassword, toggleShowPassword }) => {
  const icon = showPassword ? <img src={unlock} /> : <img src={lock} />
  return (
    <button type='button' className={styles.component} onClick={toggleShowPassword}>
      {icon}
    </button>
  )
}

export default Lock
