import React from 'react'
import styles from '../style.module.scss'
import ArrowLeft from '@icons/arrowLeft'
import { BASE_URL, defaultDataUrl, imagesUrl } from '@app/urls'
import { TransitionsModal } from '@components/Header/Modal'
import { Product } from '@app/redux/archive-slice'
import moment from 'moment'
interface ImageModalProps {
  openImageModal: boolean
  closeImageModal: () => void
  noDefectsImageLoading: boolean
  lang: string
  downloadImage: (id: number) => void
  currentProduct: Product
}
export const ImageModal = ({
  openImageModal,
  closeImageModal,
  noDefectsImageLoading,
  lang,
  downloadImage,
  currentProduct,
}: ImageModalProps) => {
  const loaderText = { RU: 'Идёт загрузка изображения...', EN: 'Downloading the image...' }
  const downloadText = { RU: 'Скачать', EN: 'Download' }
  const dateText = { RU: 'Дата', EN: 'Date' }
  const createdDateText = { RU: 'Дата изготовления', EN: 'Date of production' }
  const sectorText = { RU: 'Сектор', EN: 'Sector' }
  const typeText = { RU: 'Лот', EN: 'Lot' }
  const weightText = { RU: 'Вес, кг', EN: 'Weight, kg' }

  return (
    <TransitionsModal isOpenModal={openImageModal} setModalOpened={closeImageModal}>
      <div className={styles.opened}>
        <div className={styles.openedHeader}>
          <div onClick={closeImageModal} className={styles.openedClose}>
            <ArrowLeft />
          </div>
          <div className={styles.download_button}>
            {noDefectsImageLoading && (
              <div className={styles.loader}>
                <p>{loaderText[lang]}</p>
              </div>
            )}

            <button
              className={styles.download_btn}
              onClick={() => downloadImage(currentProduct.detection.stored_image_id)}
            >
              {downloadText[lang]}
            </button>
          </div>
        </div>
        <div className={styles.openedTable}>
          {currentProduct ? (
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{dateText[lang]}</th>
                  <th>{typeText[lang]}</th>
                  <th>{sectorText[lang]}</th>
                  <th>{weightText[lang]}</th>
                  <th>{createdDateText[lang]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currentProduct.id}</td>
                  <td>
                    <div>
                      {moment.parseZone(currentProduct.created_at).format('DD.MM.YYYY')}
                      <br />
                      {moment.parseZone(currentProduct.created_at).format('HH:mm:ss')}
                    </div>
                  </td>
                  <td>{currentProduct.lot}</td>
                  <td>{currentProduct.sector_id}</td>
                  <td>{currentProduct.weight}</td>
                  <td>{moment.parseZone(currentProduct.date).format('DD.MM.YYYY')}</td>
                </tr>
              </tbody>
            </table>
          ) : null}
        </div>
        <div className={styles.openedImage}>
          <img
            src={`${BASE_URL}${imagesUrl}/${currentProduct?.detection.stored_image_id}${defaultDataUrl}`}
            className={styles.openedImage_img}
            alt='photo'
          />
        </div>
      </div>
    </TransitionsModal>
  )
}
