import React from 'react'

import { useAppSelector, useAppDispatch } from '@app/redux/hooks'

import { RangePickerComponent } from '@components/RangePicker'

import Styles from '../style.module.scss'
import moment from 'moment'
import { format } from 'date-fns'
import { setStatisticSettings } from '@app/redux/statistic-slice'

interface IReportContent {
  modalCallback: () => void
}

export const SearchreportContent: React.FC<IReportContent> = (props) => {
  const { modalCallback } = props

  const dispatch = useAppDispatch()

  const lang: 'RU' | 'EN' = useAppSelector((state) => state.auth.lang)
  const titleText = { RU: 'Отчет', EN: 'Report' }
  const subTitleText = { RU: 'Выбор периода', EN: 'Period selection' }
  const applyFilterText = { RU: 'Сформировать отчет', EN: 'Generate report' }

  return (
    <>
      <h2 className={Styles.title}>{titleText[lang]}</h2>
      <h4 className={Styles.subtitle}>{subTitleText[lang]}</h4>
      <RangePickerComponent
        textButton={applyFilterText[lang]}
        handleChange={(dateInterval) => {
          const date0 = moment()
            // .utc()
            .set({
              year: dateInterval[0].year(),
              month: dateInterval[0].month(),
              date: dateInterval[0].date(),
              hour: dateInterval[0].hour(),
              minute: dateInterval[0].minute(),
              second: dateInterval[0].second(),
            })
            .toDate()
          const date1 = moment()
            // .utc()
            .set({
              year: dateInterval[1].year(),
              month: dateInterval[1].month(),
              date: dateInterval[1].date(),
              hour: dateInterval[1].hour(),
              minute: dateInterval[1].minute(),
              second: dateInterval[1].second(),
            })
            .toDate()
          const information = {
            dateStart: format(date0, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`),
            dateEnd: format(date1, `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`),
          }
          dispatch(setStatisticSettings(information))
          // history.push(`/image-base/filter?information=${JSON.stringify(information)}`)
          modalCallback()
        }}
        handleOnlyClick
      />
    </>
  )
}
