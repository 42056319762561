import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppSelector } from '@app/redux/hooks'
import { arrayRoutes } from '../../routes'
import { ImageBasePath } from './image-base-path'

import styles from './style.module.scss'
import { StatisticPath } from './statistics-path'

export const Header = () => {
  const lang: 'RU' | 'EN' = useAppSelector((state) => state.auth.lang)

  // Смена отображения шапки
  const [show, toggleShow] = useState(false)
  // Заголовок секции
  const [title, setTitle] = useState('')
  // Локация
  const location = useLocation()

  useEffect(() => {
    toggleShow(
      () => !(location.pathname.includes('/conveyor') || location.pathname === '/' || location.pathname === '/logout'),
    )
    setTitle((prevValue) => {
      const routeNow = arrayRoutes.find((route) => location.pathname === route.path)
      return routeNow ? (lang === 'RU' ? routeNow.content : routeNow.contentEN) : prevValue
    })
  }, [location, lang])

  return (
    <>
      {show ? (
        <header className={styles.header}>
          {location.pathname === '/image-base' || location.pathname.includes('0') ? (
            <ImageBasePath />
          ) : location.pathname === '/image-base/filter' ? (
            <ImageBasePath />
          ) : location.pathname === '/statistics' ? (
            <StatisticPath />
          ) : (
            <h1 className={styles.headerTitle}>{title}</h1>
          )}
        </header>
      ) : null}
    </>
  )
}
