import React, { useLayoutEffect, useState } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import { LoginPage } from '../pages/login'
import { ConveyorPage } from '../pages/conveyor'
import { StatisticsPage } from '../pages/statistics'
import { ImageBasePage } from '../pages/image-base'
import { OptionsPage } from '../pages/options'
import { YearPage } from '../pages/image-base/pages/year-page'
import { MonthPage } from '../pages/image-base/pages/month-page'
import { DayPage } from '../pages/image-base/pages/day-page'
import { PageNotFount } from '../pages/not-found'
import { Spinner } from '@components/Switcher/Spinner/Spinner'
import SVGStatistics from '@icons/statistics'
import SVGImageBase from '@icons/image-base'
import SVGOptions from '@icons/options'
import { ShiftPage } from '../pages/image-base/pages/shift-page'
import { SectorPage } from '../pages/image-base/pages/sector-page'
import { LogoutPage } from '../pages/logout'
import { isUserInfo } from '@app/api'
import { loginUrl } from '@app/urls'
import { FilterPage } from '../pages/image-base/pages/filter'

export const arrayRoutes = [
  {
    id: 2,
    path: '/statistics',
    content: 'СТАТИСТИКА',
    contentEN: 'STATISTICS',
    element: StatisticsPage,
    image: SVGStatistics,
  },
  {
    id: 3,
    path: '/image-base',
    content: 'АРХИВ',
    contentEN: 'ARCHIVE',
    element: ImageBasePage,
    image: SVGImageBase,
  },
  {
    id: 4,
    path: '/options',
    content: 'НАСТРОЙКИ',
    contentEN: 'SETTINGS',
    element: OptionsPage,
    image: SVGOptions,
  },
]

export const isUserInfoRedirect = (path: string) => (isUserInfo ? <Redirect to={path} /> : <Redirect to='/login' />)

const Routes: React.FC = () => {
  const history = useHistory()
  const [loader, setLoader] = useState(false) // eslint-disable-line @typescript-eslint/no-unused-vars

  useLayoutEffect(() => {
    !isUserInfo && history.push(loginUrl)
  }, [])

  return loader ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner />
    </div>
  ) : (
    <Switch>
      <Route path='/login' component={LoginPage} />
      <Route path='/logout' component={LogoutPage} />
      <Route exact path='/' render={() => isUserInfoRedirect('/conveyor/1')} />
      {arrayRoutes.map((route) => {
        return <Route exact path={route.path} key={route.id} component={route.element} />
      })}
      <Route exact path='/image-base/filter' component={FilterPage} />
      <Route path='/conveyor/:id' component={ConveyorPage} />
      <Route exact path='/image-base/:year([0-9]+)' component={YearPage} />
      <Route exact path='/image-base/:year([0-9]+)/:month([0-9]+)' component={MonthPage} />
      <Route exact path='/image-base/:year([0-9]+)/:month([0-9]+)/:day([0-9]+)' component={DayPage} />
      <Route exact path='/image-base/:year([0-9]+)/:month([0-9]+)/:day([0-9]+)/:shift' component={ShiftPage} />
      <Route exact path='/image-base/:year([0-9]+)/:month([0-9]+)/:day([0-9]+)/:shift/:sector' component={SectorPage} />
      <Route path='/not-found' component={PageNotFount} />
      <Route path='*'>
        <Redirect to='/not-found' />
      </Route>
    </Switch>
  )
}

export default Routes
