import React, { useEffect, FC, useLayoutEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@app/redux/hooks'
import { loginUrl } from '@app/urls'
import { Header } from '@components/Header'
import { NavigateMenu } from '@components/Navigation'
import SVGFolder from '@icons/folder'
import styles from './style.module.scss'
import { getHistoryYears } from '@app/redux/archive-slice'
import { getInitialData } from '@app/redux/getInitialData'

export const ImageBasePage: FC = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()
  const sectors = useAppSelector((state) => state.sectors.sectors)

  const [lang, isAuth] = useAppSelector((state) => [state.auth.lang, state.auth.isAuth])

  const loadingDataText = { RU: 'Подождите, данные загружаются...', EN: 'Please wait, data is loading ...' }
  const loadNoDataText = { RU: 'Данные отсутствуют', EN: 'No data available' }

  const historyYears = useAppSelector((state) => state.archive.historyYears)
  const [loadText, setLoadText] = React.useState<string>(loadingDataText[lang])

  useEffect(() => {
    if (isAuth) {
      dispatch(getHistoryYears({}))
        .then(() => setLoadText(() => loadNoDataText[lang]))
        .catch(() => {
          history.push(loginUrl)
        })
    }
  }, [isAuth])
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  const currentYearIndex = historyYears.findIndex((item) => item === currentYear)
  const currentHistoryYears = historyYears.slice(currentYearIndex)

  useLayoutEffect(() => {
    sectors.length > 0 ? sessionStorage.setItem('pathName', pathname) : getInitialData({ dispatch, history })
  }, [sectors])

  return (
    <div className={styles.component}>
      <Header />
      <NavigateMenu />
      <div className={styles.main_container}>
        {currentHistoryYears?.length ? (
          <>
            {currentHistoryYears.map((year) => {
              return (
                <Link key={year} to={`/image-base/${year}`} className={styles.folder}>
                  <SVGFolder />
                  <p>{year}</p>
                </Link>
              )
            })}
          </>
        ) : (
          <p className={styles.load_text}>{loadText}</p>
        )}
      </div>
    </div>
  )
}
