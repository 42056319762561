import { configureStore } from '@reduxjs/toolkit'
import { stompMiddleware } from './stomp-middleware'

import authSlice from './loginSlice'
import optionsSlice from './optionsSlice'
import { archive } from '@app/redux/archive-slice'
import { sectors } from './sectors-slice'
import webSocket from './wsSlice'
import { statistic } from './statistic-slice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    options: optionsSlice,
    archive,
    sectors,
    webSocket,
    statistic,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(stompMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatchType = typeof store.dispatch
