import React from 'react'
import styles from './style.module.scss'

import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'

export const Breadcrumbs = () => {
  const location = useLocation()

  const [actualArray] = React.useState(() => {
    const array = location.pathname.replace('image-base/', '').split('/')
    array.shift()
    return array
  })

  const history = useHistory()

  return (
    <div className={styles.breadcrumbs}>
      {actualArray
        ? actualArray.map((url, index) => {
            const array = []
            for (let i = 0; i <= index; i++) {
              array.push(actualArray[i])
            }
            return (
              <div key={url + index} className={styles.breadcrumbs__item}>
                <span
                  onClick={() => {
                    history.push(`/image-base/${array.join('/')}`)
                  }}
                  className={classNames(
                    { [`${styles.breadcrumbs__text_active}`]: url === location.pathname },
                    `${styles.breadcrumbs__text}`,
                  )}
                >
                  {url}
                </span>
                {actualArray.length !== index + 1 && <div className={styles.breadcrumbs_arrow_container}>/</div>}
              </div>
            )
          })
        : null}
    </div>
  )
}
